<template>
  <div>
    <div>
      <v-text-field
        v-model="robotModel.expectedCleaningTime"
        class="leftblock"
        :label="$t('form.label.expectedCleaningTime')"
        name="expectedCleaningTime"
        :rules="[rules.required, rules.number]"
        @input="notify"
      />
      <v-text-field
        v-model="robotModel.floorId"
        class="rightblock"
        :label="$t('form.label.floorId')"
        name="floorId"
        :rules="[rules.required, rules.number]"
        @input="notify"
      />
    </div>
    <v-divider v-if="robotModel.sendCleanPilot" />
    <v-checkbox
      v-model="robotModel.sendCleanPilot"
      class="leftblock"
      :label="$t('form.label.sendCleanPilot')"
      input-value="true"
      @change="notify"
    />
    <div v-if="robotModel.sendCleanPilot">
      <v-text-field
        v-model="robotModel.roomId"
        class="leftblock"
        :label="$t('form.label.roomId')"
        name="roomId"
        :rules="[rules.required]"
        @input="datecNotify"
      />
      <v-text-field
        v-model="robotModel.url"
        class="rightblock"
        :label="$t('form.label.serverUrl')"
        name="serverUrl"
        :rules="[rules.required]"
        @input="datecNotify"
      />
      <v-btn
        v-if="robotModel.sendCleanPilot"
        color="info"
        block
        @click="testIntegration"
      >
        {{ $t('form.label.testIntegration') }}
        <v-icon
          v-if="robotModel.testPassed"
          dark
          right
        >
          mdi-checkbox-marked-circle
        </v-icon>
        <v-progress-circular
          v-if="testInProgress"
          :size="20"
          class="progress"
          :indeterminate="testInProgress"
          absolute
          color="white"
        />
      </v-btn>
      <v-spacer />
      <v-spacer />
      <v-divider />
    </div>
    <div>
      <p class="label">
        {{ $t('form.label.daysoff') }}
      </p>
      <v-checkbox
        v-model="daysOff"
        class="days-off"
        :label="$t('form.label.monday')"
        value="1"
        @change="notify"
      />
      <v-checkbox
        v-model="daysOff"
        class="days-off"
        :label="$t('form.label.tuesday')"
        value="2"
        @change="notify"
      />
      <v-checkbox
        v-model="daysOff"
        class="days-off"
        :label="$t('form.label.wednesday')"
        value="3"
        @change="notify"
      />
      <v-checkbox
        v-model="daysOff"
        class="days-off"
        :label="$t('form.label.thursday')"
        value="4"
        @change="notify"
      />
      <v-checkbox
        v-model="daysOff"
        class="days-off"
        :label="$t('form.label.friday')"
        value="5"
        @change="notify"
      />
      <v-checkbox
        v-model="daysOff"
        class="days-off"
        :label="$t('form.label.saturday')"
        value="6"
        @change="notify"
      />
      <v-checkbox
        v-model="daysOff"
        class="days-off"
        :label="$t('form.label.sunday')"
        value="0"
        @change="notify"
      />
    </div>
    <DialogTestIntegration
      :show="showIntegrationDialog"
      :color="alertColor"
      :alert-text="alertString"
      @confirm="closeTestDialog"
    />
  </div>
</template>

<script>
import { required, number } from '@/validation/rules';
import DialogTestIntegration from '@/components/Dialog/TestIntegration';
import { testIntegration } from '@/helpers/apiCall';

const DEFAULT_ROBOT = {
  roomId: '',
  expectedCleaningTime: '',
  floorId: '',
  excludedDays: ['6', '0'],
  url: '',
  sendCleanPilot: false,
  testPassed: false,
};

export default {
  name: 'RobotFormComponent',
  components: {
    DialogTestIntegration,
  },
  props: {
    zone: {
      type: Object,
      default: null,
    },
    alertText: {
      type: String,
      default: '',
    },
    color: {
      type: String,
      default: '',
    },
    showTestIntegrationDialog: {
      type: Boolean,
      default: false,
    },
    addFromAnotherForm: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      robotModel: { ...DEFAULT_ROBOT },
      testInProgress: false,
      showIntegrationDialog: this.showTestIntegrationDialog,
      alertColor: this.color,
      alertString: this.alertText,
      rules: {
        required,
        number,
      },
      daysOff: [],
    };
  },
  watch: {
    zone(value) {
      if (value) {
        this.robotModel = { ...value };
      } else {
        this.robotModel = { ...DEFAULT_ROBOT };
        this.$refs.form.reset();
      }
    },
    showTestIntegrationDialog(newVal) {
      if (newVal === true) {
        this.showIntegrationDialog = newVal;
        this.$emit('clearTestIntegrationDialogFlag');
      }
    },
    color() {
      this.alertColor = this.color;
    },
    alertText() {
      this.alertString = this.alertText;
    },
  },
  created() {
    this.robotModel = this.zone === null || this.addFromAnotherForm ? ({ ...DEFAULT_ROBOT }) : { ...this.zone };
    this.daysOff = this.zone === null || this.addFromAnotherForm ? DEFAULT_ROBOT.excludedDays : [];
  },
  mounted() {
    if (this.zone) {
      if (this.robotModel.sendCleanPilot) {
        this.robotModel.testPassed = true;
      }
      if (this.zone.scenarios !== undefined) {
        if (this.zone.scenarios.length > 0) {
          this.zone.scenarios.forEach((scenario) => {
            this.daysOff.push(scenario.ddow_id.toString());
          });
        }
      }
    }
    this.notify();
  },
  methods: {
    notify() {
      this.robotModel.excludedDays = this.daysOff;
      this.$emit('updated', this.robotModel);
    },
    datecNotify() {
      this.robotModel.testPassed = false;
      this.$emit('updated', this.robotModel);
    },
    async testIntegration() {
      this.robotModel.url = this.robotModel.url.trim();
      this.robotModel.roomId = this.robotModel.roomId.trim();
      let response = {};
      if (this.robotModel.testPassed !== true) {
        if (this.robotModel.url === '' || this.robotModel.roomId === '') {
          this.alertString = this.$t('integrationTest.emptyFields');
          this.alertColor = 'info';
          this.showIntegrationDialog = true;
        } else {
          this.testInProgress = true;
          response = await testIntegration(this.robotModel.roomId, this.robotModel.url);
          this.testInProgress = false;
          if (response.status === 200) {
            this.robotModel.testPassed = (response.data.status_code === 204);
            if (this.robotModel.testPassed === false) {
              this.alertString = this.$t('integrationTest.testFailed');
              this.alertColor = 'error';
              this.showIntegrationDialog = true;
            }
          } else {
            this.alertString = this.$t('integrationTest.noConnectionWithAPI');
            this.alertColor = 'error';
            this.showIntegrationDialog = true;
          }
        }
      }
      this.notify();
      // `event` is the native DOM event
    },
    closeTestDialog() {
      this.showIntegrationDialog = false;
    },
  },

};
</script>

<style scoped>
.rightblock {
  width:45%;
  display: inline-block;
  margin:5%;
  margin-top: 10px;
  margin-bottom: 0px;
}
.leftblock {
  margin-top: 10px;
  margin-bottom: 0px;
  width:45%;
  display: inline-block;
}
.days-off {
  margin-right: 10px;
  margin-top: 15px;
  display: inline-block;
}
.label {
  margin-top: 0px;
  width:30%;
  display: inline-block;
  font-size: 1rem;
  color: gray;
}
</style>
