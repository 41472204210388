<template>
  <v-card class="fill-height">
    <ManagementBar :title="$tc('common.partner', 2).capitalize()" :listsize="partners.length">
      <v-text-field
        v-model="search"
        prepend-inner-icon="mdi-magnify"
        :label="`${$t('entity.find', { entity: $tc('common.partner', 1) })}...`.capitalize()"
        single-line
        hide-details
      />
      <template v-if="isSuperadmin" #actions>
        <v-btn color="success" small @click.stop="openDialog">
          <v-icon small>
            mdi-plus
          </v-icon>
          {{ $t("entity.new", { entity: $tc("common.partner", 1) }) }}
        </v-btn>
      </template>
    </ManagementBar>
    <v-data-table
      :headers="headers"
      :items="partners"
      :search="search"
      :loading="loading"
      :footer-props="{
        itemsPerPageOptions: [20, 50, 100, -1]
      }"
      :disable-pagination="false"
      item-key="id"
      fixed-header
      sort-by="[name]"
    >
      <template #[`item.n3clients`]="{item}">
        <span v-for="client in item.n3clients" :key="client.name">
          <v-chip>
            {{ client.name }}
          </v-chip>
        </span>
      </template>
      <template #[`item.n3devices`]="{item}">
        <span v-for="device in item.n3devices" :key="device.name">
          <v-chip>
            {{ device.name }}
          </v-chip>
        </span>
      </template>
      <template #[`item.partner_has_users`]="{item}">
        <span v-for="user in item.partner_has_users" :key="user.n3user.client_id">
          <v-chip>
            {{ user.n3user.email }}
          </v-chip>
        </span>
      </template>

      <template #[`item.action`]="{ item }">
        <span class="actions">
          <v-icon v-if="isSuperadmin" small class="mr-2" @click.stop="editItem(item)">
            mdi-pencil
          </v-icon>
          <v-icon v-if="isSuperadmin" small @click.stop="confirmDeleteItem(item)">
            mdi-delete
          </v-icon>
        </span>
      </template>
    </v-data-table>

    <v-dialog v-model="dialog" max-width="480px">
      <ManagementPartnerForm :partner="partnerToEdit" @submit="submit" @close="closeDialog" />
    </v-dialog>
    <DialogConfirm
      :show="confirmDialog"
      :danger="true"
      @cancel="closeConfirmDialog"
      @confirm="deleteItem"
    >
      {{ $t("confirmation.delete.title") }}
    </DialogConfirm>
  </v-card>
</template>
<script>
import ManagementBar from '@/components/ManagementBar';
import {
  addPartner, fetchPartners, deletePartner, updatePartner,
} from '@/data';
import { EventBus } from '@/eventBus';
import ManagementPartnerForm from '@/views/Management/Partners/Form';
import DialogConfirm from '@/components/Dialog/Confirm';

export default {
  name: 'DpManagementPartners',
  components: {
    ManagementBar,
    ManagementPartnerForm,
    DialogConfirm,
  },
  inject: ['authGuard'],
  props: {
    searchName: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      partners: [],
      search: this.searchName,
      loading: false,
      dialog: false,
      partnerToEdit: null,
      confirmDialog: false,
      partnerToRemove: null,
      headers: [
        { text: this.$t('table.client.header.name'), value: 'name' },
        {
          text: '',
          value: 'action',
          filterable: false,
          sortable: false,
          width: '80px',
        },
      ],
    };
  },
  computed: {
    isSuperadmin() {
      return (
        this.authGuard.isGranted('n3-superadmin')
        || this.authGuard.isGranted('n3-partner')
        || this.authGuard.isGranted('n3-admin')
      );
    },
  },

  watch: {
    dialog(newVal, oldVal) {
      if (oldVal === true && newVal === false) {
        this.partnerToEdit = null;
      }
    },
  },
  created() {
    this.loadPartners();
  },
  methods: {
    async loadPartners() {
      try {
        this.partners = await fetchPartners();
        this.loading = false;
      } catch (e) {
        EventBus.$emit('generalErrorOccurred', {
          message: 'Cannot load data. Try again in a few seconds',
        });
        console.error(e); // eslint-disable-line
      }
    },
    openDialog() {
      this.dialog = true;
    },
    closeDialog() {
      this.dialog = false;
    },
    confirmDeleteItem(item) {
      if (this.authGuard.isGranted('n3-superadmin')) {
        this.partnerToRemove = item;
        this.confirmDialog = true;
      }
    },
    closeConfirmDialog() {
      this.confirmDialog = false;
    },
    submit(submitted) {
      const { name } = submitted;
      if (this.partnerToEdit) {
        const index = this.partners.indexOf(this.partnerToEdit);
        updatePartner(this.partnerToEdit.partner_id, { name }, this.partnerToEdit)
          .then((updatedPartner) => {
            this.partners.splice(index, 1, updatedPartner);
          })
          .catch((error) => {
            EventBus.$emit('generalErrorOccurred');
            console.error(error); // eslint-disable-line
          })
          .finally(this.closeDialog);
      } else {
        addPartner(name)
          .then((createdPartner) => {
            this.partners.push(createdPartner);
          })
          .catch((error) => {
            EventBus.$emit('generalErrorOccurred', {
              message: 'Partner alredy exists',
            });
          console.error(error); // eslint-disable-line
          })
          .finally(this.closeDialog);
      }
    },
    editItem(item) {
      if (
        this.authGuard.isGranted('n3-superadmin')
        || this.authGuard.isGranted('n3-partner')
        || this.authGuard.isGranted('n3-admin')
      ) {
        this.partnerToEdit = item;
        this.openDialog();
      }
    },
    deleteItem() {
      const index = this.partners.indexOf(this.partnerToRemove);
      if (index < 0) {
        console.error("This should not happen"); // eslint-disable-line

        return;
      }
      this.partners.splice(index, 1);
      this.closeConfirmDialog();
      deletePartner(this.partnerToRemove.partner_id, this.partners).catch((error) => {
        EventBus.$emit('generalErrorOccurred', {
          message: 'Cannot remove client',
        });
        this.partners.splice(index, 0, this.partnerToRemove);
        console.error(error); // eslint-disable-line
      });
    },
  },
};
</script>

<style lang="scss" scoped>
table {
  tr {
    .actions {
      .v-icon {
        visibility: hidden;
      }
    }
    &:hover {
      .actions {
        .v-icon {
          visibility: visible;
        }
      }
    }
  }
}
</style>
