<template>
  <v-dialog v-model="visible" max-width="550px">
    <v-card>
      <v-alert
        :type="color"
      >
        {{ alertText }}
      </v-alert>
      <v-card-actions>
        <v-spacer />
        <v-btn :color="type" @click="confirm">
          {{ $t('integrationTest.confirm') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'DialogTestIntegration',
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    color: {
      type: String,
      required: true,
    },
    alertText: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      visible: false,
    };
  },
  computed: {
    type() {
      return 'primary';
    },
  },
  watch: {
    show(newVal) {
      this.visible = newVal;
    },
  },
  methods: {
    confirm() {
      this.$emit('confirm');
    },
  },
};
</script>

<style lang="scss" scoped>
  .v-btn:not(.v-btn--outlined).danger {
    color: #ffffff;
  }
  .text-center{
    font-size: 1.15rem;
  }
</style>
