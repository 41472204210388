<template>
  <div class="map-wrapper" />
</template>

<script>

import {
  addMarker,
  addMarkerClusters,
  createMap,
  fitBounds,
  gmapInit,
} from '@/integration/google';

/**
 * Props "locations" should have type: {{lat: Number, lng: Number}}
 * @TODO not fully tested for more than 1 location
 */
export default {
  name: 'Map',
  props: {
    center: {
      type: Object,
      default: () => ({ lat: 59.508742, lng: 11.12085 }),
    },
    zoom: {
      type: Number,
      default: 6,
    },
    locations: {
      type: Array,
      default: () => [],
    },
    allowAdd: {
      type: Boolean,
      default: false,
    },
    allowDrag: {
      type: Boolean,
      default: false,
    },
    maxMarkers: {
      type: Number,
      default: -1,
    },
    allowClusters: {
      type: Boolean,
      default: false,
    },
    markerIcon: {
      type: String,
      default: '',
    },
    maptype: {
      type: String,
      default: 'roadmap',
    },
  },
  data() {
    return {
      markers: [],
      map: null,
      markerClusterer: null,
      dim: null,
    };
  },
  watch: {
    locations(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.markers.forEach((marker) => {
          marker.setMap(null);
        });
        this.markers = [];
        this.loadMarkers();
        this.addMarkerClusters();
        this.getDimensions();
        this.fitMarkers();
      }
    },
  },
  async beforeMount() {
    try {
      await gmapInit();
      if (!this.locations.length) {
        this.map = createMap(this.$el, {
          center: { lat: this.center.lat, lng: this.center.lng },
          zoom: this.zoom,
          mapTypeId: this.maptype,
        });
      } else {
        this.map = createMap(this.$el);
        this.loadMarkers();
        this.addMarkerClusters();
        await this.$nextTick();
        this.getDimensions();
        this.fitMarkers();
      }
      this.changeMapType();
      this.map.addListener('click', this.mapClickHandler);
    } catch (error) {
      console.error(error); // eslint-disable-line
      throw new Error('Map init failed');
    }
  },
  methods: {
    loadMarkers() {
      this.locations.forEach(this.addMarker);
    },
    getDimensions() {
      const height = this.$el.clientHeight;
      const width = this.$el.clientWidth;
      this.dim = { height, width };
    },
    addMarker(markerData) {
      const { lat, lng, title = null } = markerData;
      const marker = addMarker(
        this.map,
        { lat, lng },
        { draggable: this.allowDrag },
        this.markerIcon,
      );
      if (title) {
        marker.setTitle(title);
      }

      marker.addListener('click', () => this.$emit('marker:clicked', markerData));
      if (this.allowDrag) {
        marker.addListener('dragend', this.markersChanged);
      }

      this.markers.push(marker);

      return marker;
    },
    addMarkerClusters() {
      if (this.allowClusters) {
        addMarkerClusters(
          this.map,
          this.markers,
        );
      }
    },
    fitMarkers() {
      if (this.locations.length) {
        fitBounds(this.map, this.locations, this.dim);
      }
    },
    changeMapType() {
      this.map.setMapTypeId(this.maptype);
    },
    mapClickHandler(event) {
      if (!this.allowAdd) {
        return event;
      }

      if (this.maxMarkers === 1) {
        this.$emit('update:locations', [{ lat: event.latLng.lat(), lng: event.latLng.lng() }]);

        return event;
      }

      return event;
    },
    markersChanged() {
      const newLocations = [];
      this.markers.forEach((marker) => {
        newLocations.push({ lat: marker.position.lat(), lng: marker.position.lng() });
      });
      this.$emit('update:locations', newLocations);
    },
  },
};
</script>

<style scoped>
  .map-wrapper {
    width: 100%;
    height: 100%;
  }
</style>
