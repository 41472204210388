<template>
  <div>
    <v-card>
      <v-container v-if="!addOnlyZone && ! addOnlyDevice">
        <v-form
          ref="form"
          v-model="valid"
          novalidate="true"
          @submit.prevent="closeForm"
        >
          <v-card-text>
            <v-row justify="center" align="center">
              <v-col cols="6">
                <v-btn
                  block
                  @click.stop="addZone"
                >
                  <v-icon left>
                    mdi-domain
                  </v-icon>
                  {{ $t('form.label.addZone') }}
                </v-btn>
              </v-col>
              <v-col cols="6">
                <v-btn
                  block
                  @click.stop="addDevice"
                >
                  <v-icon left>
                    mdi-robot-vacuum
                  </v-icon>
                  {{ $t('form.label.addDevice') }}
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-form>
      </v-container>
    </v-card>
    <v-dialog v-model="deviceDialog" max-width="600px" @click:outside="closeDeviceDialog">
      <DeviceFormContainer
        :locations="locations"
        :device="deviceToEdit"
        :disabled="disabledInputsDevice"
        :add-from-another-form="true"
        :is-update="false"
        @createDevice="createDevice"
        @closeDeviceDialog="closeDeviceDialog"
      />
    </v-dialog>
    <v-dialog v-model="zoneDialog" max-width="1000" @click:outside="closeZoneDialog">
      <FormContainer
        :locations="locations"
        :zone="zoneToEdit"
        :disabled="disabledInputsZone"
        :add-from-another-form="true"
        :device="device"
        @create="createZone"
        @close="closeZoneDialog"
      />
    </v-dialog>
  </div>
</template>

<script>

import DeviceFormContainer from '@/views/Management/Devices/FormContainer';
import FormContainer from '@/views/Management/Zones/FormContainer';

export default {
  name: 'ManagmentLocationForm',
  components: {
    FormContainer,
    DeviceFormContainer,
  },
  inject: ['authGuard'],
  props: {
    location: {
      type: Object,
      default: null,
    },
    addOnlyZone: {
      type: Boolean,
      default: false,
    },
    addOnlyDevice: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      zoneToSend: {
        name: '',
        sensor: '',
        category: '',
        locationId: this.location?.id,
        sendNotification: true,
        gpsLatitude: '',
        gpsLongitude: '',
      },
      deviceToSend: {
        name: '',
        subcategory: '',
        category: '',
        sensorIdentifier: '',
        externalEquipment: '',
        gpsLatitude: '',
        gpsLongitude: '',
        locationId: this.location?.id,
      },
      deviceDialog: false,
      freeDevice: null,
      zoneDialog: false,
      valid: true,
      disabledInputsDevice: ['location'],
    };
  },
  computed: {
    device: {
      get() {
        return this.freeDevice;
      },
      set(device) {
        this.freeDevice = device;
      },
    },
    deviceToEdit() {
      return this.deviceToSend;
    },
    zoneToEdit() {
      return this.zoneToSend;
    },
    locationID() {
      return this.location?.id;
    },
    locations() {
      return [{ loc_id: this.location?.id, loc_name: this.location?.name, partner_has_locs: this.location?.partnersLocs }];
    },
    disabledInputsZone() {
      if (this.authGuard.isGranted('n3-admin') || this.authGuard.isGranted('n3-superadmin') || this.authGuard.isGranted('n3-admin-mid-leader') || this.authGuard.isGranted('n3-partner')) {
        return ['location'];
      }

      return ['location', 'name'];
    },
  },
  watch: {
    location(newVal) {
      if (newVal !== null) {
        this.deviceToSend = {
          name: '',
          subcategory: '',
          category: '',
          sensorIdentifier: '',
          externalEquipment: '',
          gpsLatitude: '',
          gpsLongitude: '',
          locationId: this.locationID,
        };
        this.zoneToSend = {
          name: '',
          sensor: '',
          category: '',
          locationId: this.locationID,
          sendNotification: true,
          gpsLatitude: '',
          gpsLongitude: '',
        };
        if (this.addOnlyZone) {
          this.addZone();
        } else if (this.addOnlyDevice) {
          this.addDevice();
        }
      }
    },
  },
  mounted() {
    if (this.addOnlyZone && this.location) {
      this.addZone();
    } else if (this.addOnlyDevice && this.location) {
      this.addDevice();
    }
  },
  methods: {
    addDevice() {
      this.deviceDialog = true;
      this.deviceToSend = {
        name: '',
        subcategory: '',
        category: '',
        sensorIdentifier: '',
        externalEquipment: '',
        gpsLatitude: '',
        gpsLongitude: '',
        locationId: this.locationID,
      };
    },
    addZone() {
      this.zoneDialog = true;
      this.zoneToSend = {
        name: '',
        sensor: '',
        category: '',
        locationId: this.locationID,
        sendNotification: true,
        gpsLatitude: '',
        gpsLongitude: '',
      };
    },
    closeZoneDialog() {
      this.zoneDialog = false;
      if (this.addOnlyZone) {
        this.closeForm();
      }
    },
    closeDeviceDialog() {
      this.deviceDialog = false;
      if (this.addOnlyDevice) {
        this.closeForm();
      }
    },
    closeForm() {
      this.$emit('close');
      if (this.$refs.form) {
        this.$refs.form.reset();
      }
    },
    createDevice(device) {
      this.$emit('addDevice', device);
      this.deviceToSend = null;
      this.device = device;
    },
    createZone({ createdZone, category }) {
      this.$emit('addZone', { createdZone, category });
      this.zoneToSend = null;
    },
  },
};
</script>
