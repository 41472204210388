var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"fill-height"},[_c('ManagementBar',{attrs:{"title":_vm.$tc('common.client', 2).capitalize(),"listsize":_vm.clients.length},scopedSlots:_vm._u([(_vm.isSuperadminOrPartner)?{key:"actions",fn:function(){return [_c('v-btn',{attrs:{"color":"success","small":""},on:{"click":function($event){$event.stopPropagation();return _vm.openDialog($event)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-plus ")]),_vm._v(" "+_vm._s(_vm.$t("entity.new", { entity: _vm.$tc("common.client", 1) }))+" ")],1)]},proxy:true}:null],null,true)},[_c('v-text-field',{attrs:{"prepend-inner-icon":"mdi-magnify","label":((_vm.$t('entity.find', { entity: _vm.$tc('common.client', 1) })) + "...").capitalize(),"single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),(_vm.isSuperadmin)?_c('v-select',{staticClass:"v-select",attrs:{"loading":_vm.loading,"items":_vm.partners,"item-text":"name","item-value":"partner_id","flat":"","solo":"","label":_vm.$t('common.partner'),"clearable":""},model:{value:(_vm.selectedPartner),callback:function ($$v) {_vm.selectedPartner=$$v},expression:"selectedPartner"}}):_vm._e()],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.clientsToDisplay,"search":_vm.search,"loading":_vm.loading,"footer-props":{
      itemsPerPageOptions: [20, 50, 100, -1]
    },"disable-pagination":false,"item-key":"id","fixed-header":"","sort-by":"[name]","expanded":_vm.expanded},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"loading",fn:function(){return [_c('v-skeleton-loader',{staticClass:"mx-auto",attrs:{"max-width":"80%","type":"table-row@3"}})]},proxy:true},(_vm.isSuperadminOrPartner)?{key:"no-data",fn:function(){return [_c('v-card-text',{staticClass:"pa-8"},[_c('v-icon',{staticClass:"mb-6",attrs:{"slot":"icon","size":"64"},slot:"icon"},[_vm._v(" mdi-city ")]),_c('p',{staticClass:"display-1 text--primary"},[_vm._v(" "+_vm._s(_vm.$t("entity.empty.headline", { entities: _vm.$tc("common.client", 2) }))+" ")]),_c('p',[_vm._v(_vm._s(_vm.$t("entity.empty.description", { entities: _vm.$tc("common.client", 2) })))]),_c('v-btn',{attrs:{"tile":"","color":"blue lighten-4"},on:{"click":function($event){$event.stopPropagation();return _vm.openDialog($event)}}},[_vm._v(" "+_vm._s(_vm.$t("entity.add", { entity: _vm.$tc("common.client", 1) }))+" ")])],1)]},proxy:true}:null,{key:"item.partner_has_clis",fn:function(ref){
    var item = ref.item;
return [(item.partner_has_clis[0])?_c('span',[_vm._v(" "+_vm._s(item.partner_has_clis[0].n3partner.name)+" ")]):_vm._e(),(item.partner_has_clis[1])?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.expandRow(item)}}},[(_vm.expanded.includes(item))?_c('v-icon',{attrs:{"size":"16"}},[_vm._v(" mdi-chevron-up ")]):_c('span',{staticClass:"caption"},[_vm._v(" "+_vm._s(item.partner_has_clis.length - 1)+"+ ")])],1):_vm._e()]}},{key:"expanded-item",fn:function(ref){
    var item = ref.item;
return [_c('td',{attrs:{"colspan":2}}),_c('td',{attrs:{"colspan":3}},[_c('p',{staticClass:"mt-4 mb-4"},_vm._l((item.partner_has_clis),function(partner){return _c('span',{key:partner.partner_id},[_c('li',[_vm._v(" "+_vm._s(partner.n3partner.name)+" ")])])}),0)])]}},{key:"item.action",fn:function(ref){
    var item = ref.item;
return [_c('span',{staticClass:"actions"},[(_vm.isSuperadminOrPartner)?_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){$event.stopPropagation();return _vm.editItem(item)}}},[_vm._v(" mdi-pencil ")]):_vm._e(),(_vm.isSuperadminOrPartner)?_c('v-icon',{attrs:{"small":""},on:{"click":function($event){$event.stopPropagation();return _vm.confirmDeleteItem(item)}}},[_vm._v(" mdi-delete ")]):_vm._e()],1)]}}],null,true)}),_c('v-dialog',{attrs:{"max-width":"480px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('ManagementClientForm',{attrs:{"client":_vm.clientToEdit,"partners":_vm.partners},on:{"submit":_vm.submit,"close":_vm.closeDialog}})],1),_c('DialogConfirm',{attrs:{"show":_vm.confirmDialog,"danger":true},on:{"cancel":_vm.closeConfirmDialog,"confirm":_vm.deleteItem}},[_vm._v(" "+_vm._s(_vm.$t("confirmation.delete.title"))+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }