<template>
  <v-card class="fill-height">
    <ManagementBar :title="$tc('common.zone', 2).capitalize()" :listsize="zones.length">
      <v-text-field
        v-model="search"
        prepend-inner-icon="mdi-magnify"
        :label="`${$t('entity.find', { entity: $tc('common.zone', 1) })}...`.capitalize()"
        single-line
        hide-details
      />
      <template
        v-if="isAdmin"
        #actions
      >
        <v-btn
          color="success"
          small
          @click.stop="openDialog"
        >
          <v-icon small>
            mdi-plus
          </v-icon>
          {{ $t('entity.new', { entity: $tc('common.zone', 1) }) }}
        </v-btn>
      </template>
      <v-select
        v-model="category"
        class="v-select"
        flat
        solo
        :loading="loading"
        :items="categoriesArray"
        item-text="category_name"
        item-value="category_id"
        label="Category"
        hide-no-data
      />
      <v-select
        v-if="isSuperAdmin"
        v-model="selectedPartner"
        :loading="loading"
        :items="partners"
        item-text="name"
        item-value="partner_id"
        class="v-select"
        flat
        solo
        :label="$t('common.partner')"
        clearable
      />
    </ManagementBar>
    <v-data-table
      :headers="headers"
      :items="zones"
      :search="search"
      :loading="loading"
      item-key="key"
      :footer-props="{
        itemsPerPageOptions:[20, 50, 100, -1]
      }"
      :disable-pagination="false"
      :custom-sort="customSort"
      :custom-filter="customSearch"
      fixed-header
    >
      <template #loading>
        <v-skeleton-loader
          class="mx-auto"
          max-width="80%"
          type="table-row@3"
        />
      </template>

      <template #no-data>
        <v-card-text class="pa-8">
          <v-icon
            slot="icon"
            size="64"
            class="mb-6"
          >
            mdi-city
          </v-icon>
          <p class="display-1 text--primary">
            {{ $t('entity.empty.headline', { entities: $tc('common.zone', 2) }) }}
          </p>
          <p>{{ $t('entity.empty.description', { entities: $tc('common.zone', 2) }) }}</p>
          <v-btn
            tile
            color="blue lighten-4"
            @click="openDialog"
          >
            {{ $t('entity.add', { entity: $tc('common.zone', 1) }) }}
          </v-btn>
        </v-card-text>
      </template>
      <template #[`item.client`]="{item}">
        <v-chip v-if="item.client" color="white" @click="redirect('clients', item.client.name)">
          {{ item.client.name }}
        </v-chip>
      </template>
      <template #[`item.location`]="{item}">
        <v-chip v-if="item.location" color="white" @click="redirect('locations', item.location.loc_name)">
          {{ item.location.loc_name }}
        </v-chip>
      </template>
      <template #[`item.device`]="{item}">
        <v-chip color="white" @click="redirect('devices', item.device.name)">
          {{ item.device.name }}
        </v-chip>
      </template>

      <template #[`item.n3partner`]="{item}">
        <v-chip v-if="item.n3partner" color="white" @click="(isSuperAdmin) ? redirect('partners', item.n3partner.name) : () => {}">
          {{ item.n3partner.name }}
        </v-chip>
      </template>

      <template #[`item.gpsLatitude`]="{item}">
        {{ item.gpsLatitude }}, {{ item.gpsLongitude }}
      </template>

      <template #[`item.created`]="{item}">
        {{ item.created | gmtTime | dateWithTime }}
      </template>

      <template #[`item.sendCleanPilot`]="{item}">
        <v-icon
          v-if="item.sendCleanPilot"
          small
          class="mr-2"
        >
          mdi-check-circle
        </v-icon>
      </template>

      <template #[`item.action`]="{ item }">
        <span class="actions">
          <v-icon
            small
            class="mr-2"
            @click.stop="editItem(item)"
          >
            mdi-pencil
          </v-icon>
          <v-icon
            v-if="isAdmin"
            small
            @click.stop="confirmDeleteItem(item)"
          >
            mdi-delete
          </v-icon>
        </span>
      </template>
    </v-data-table>
    <v-dialog v-model="dialog" max-width="1000">
      <FormContainer
        :zone="zoneToEdit"
        :locations="locations"
        :disabled="disabledInputs"
        :device="device"
        @update="update"
        @create="create"
        @close="closeDialog"
      />
    </v-dialog>
    <DialogConfirm
      :show="confirmDialog"
      :danger="true"
      @cancel="closeConfirmDialog"
      @confirm="deleteItem"
    >
      {{ $t('confirmation.delete.title') }}
    </DialogConfirm>
  </v-card>
</template>

<script>

import {
  fetchZones, deleteZone, fetchLocationForZonesAndDevices, deleteRobotZone, deleteEnvironmentZone, fetchPartners,
} from '@/data';
import ManagementBar from '@/components/ManagementBar';
import FormContainer from '@/views/Management/Zones/FormContainer';
import DialogConfirm from '@/components/Dialog/Confirm';
import { EventBus } from '@/eventBus';
import { CategoriesNames } from '@/model/CategoriesObjects';
import { ZoneZone, RobotZone } from '@/model/zones/SubZones';
import EnvironmentZone from '@/model/zones/EnvironmentZone';
import { headerSearch } from '@/helpers/filteringManagementList';
import { compare, compareObjects } from '@/helpers/sortManagementList';

export default {
  name: 'ManagementZoneList',
  components: {
    ManagementBar,
    FormContainer,
    DialogConfirm,
  },
  inject: ['authGuard'],
  props: {
    searchName: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      newValue: null,
      freeDevice: null,
      zoneToDisplay: [],
      locations: [],
      zoneToEdit: null,
      zoneToRemove: null,
      search: this.searchName,
      loading: false,
      dialog: false,
      confirmDialog: false,
      categories: Object.values(CategoriesNames),
      categoriesArray: [],
      category: 'All',
      ALL_CATEGORIES: 'All',
      partners: [],
      selectedPartner: null,
      headersN3Zone: [
        { text: this.$t('table.zone.header.sendCleanPilot'), value: 'sendCleanPilot' },
        { text: this.$t('table.zone.header.zoneSize'), value: 'zoneSize' },
        { text: this.$t('table.zone.header.expectedCleaningTime'), value: 'expectedCleaningTime' },
      ],
      headersN3Robots: [
        { text: this.$t('table.zone.header.sendCleanPilot'), value: 'sendCleanPilot' },
        { text: this.$t('table.zone.header.floorId'), value: 'floorId' },
        { text: this.$t('table.zone.header.expectedCleaningTime'), value: 'expectedCleaningTime' },
      ],
      headersN3Environment: [
        { text: this.$t('table.zone.header.roomId'), value: 'roomId' },
        { text: this.$t('table.zone.header.floorId'), value: 'floorId' },
      ],
      headersAll: [
        { text: this.$t('table.zone.header.name'), value: 'name', width: '210px' },
        { text: this.$t('table.zone.header.sensor'), value: 'device', width: '190px' },
        { text: this.$t('table.zone.header.externalEquipment'), value: 'device.externalEquipment', width: '170px' },
        { text: this.$t('table.zone.header.subcategory'), value: 'device.subcategory', width: '120px' },
        {
          text: 'GPS', value: 'gpsLatitude', width: '180px', sortable: false,
        },
        { text: this.$t('table.zone.header.location'), value: 'location', width: '200px' },
        { text: this.$t('table.zone.header.client'), value: 'client', width: '180px' },
        { text: this.$t('table.zone.header.partner'), value: 'n3partner', width: '170px' },
        { text: this.$t('table.zone.header.created'), value: 'created', width: '170px' },
        {
          text: '',
          value: 'action',
          filterable: false,
          sortable: false,
          width: '80px',
        },
      ],
    };
  },
  computed: {
    device: {
      get() {
        return this.freeDevice;
      },
      set(device) {
        this.freeDevice = device;
      },
    },
    headers() {
      const headersToDisplay = this.headersAll.slice();
      if (this.category === CategoriesNames.N3Zone) {
        headersToDisplay.splice(6, 0, this.headersN3Zone[0]);
        headersToDisplay.splice(7, 0, this.headersN3Zone[1]);
      } else if (this.category === CategoriesNames.N3Robot) {
        headersToDisplay.splice(6, 0, this.headersN3Robots[0]);
        headersToDisplay.splice(7, 0, this.headersN3Robots[1]);
      } else if (this.category === CategoriesNames.N3Environment) {
        headersToDisplay.splice(6, 0, this.headersN3Environment[0]);
        headersToDisplay.splice(7, 0, this.headersN3Environment[1]);
      }

      return headersToDisplay;
    },
    zones() {
      let zones = [];
      if (this.category === this.ALL_CATEGORIES) {
        zones = this.zoneToDisplay;
      } else {
        zones = this.zoneToDisplay.filter((e) => e.category === this.category);
      }

      if (this.selectedPartner) {
        return zones.filter((zone) => zone.partnerId === this.selectedPartner);
      }

      return zones;
    },
    isAdmin() {
      return this.authGuard.isGranted('n3-superadmin') || this.authGuard.isGranted('n3-admin') || this.authGuard.isGranted('n3-admin-mid-leader') || this.authGuard.isGranted('n3-partner');
    },
    disabledInputs() {
      if (this.authGuard.isGranted('n3-admin') || this.authGuard.isGranted('n3-superadmin') || this.authGuard.isGranted('n3-admin-mid-leader') || this.authGuard.isGranted('n3-partner')) {
        return [];
      }

      return ['location', 'name'];
    },
    isSuperAdmin() {
      return this.authGuard.isGranted('n3-superadmin');
    },
  },
  watch: {
    dialog(newVal, oldVal) {
      if (oldVal === true && newVal === false) {
        this.zoneToEdit = null;
        this.newValue = !!this.newValue;
      }
    },
  },
  created() {
    this.loadZones();
    this.loadLocations();
    this.loadCategories();
    this.loadPartners();
  },
  methods: {
    async loadZones() {
      this.loading = true;
      try {
        const allZones = await fetchZones();
        allZones.zones.forEach((zone) => {
          this.zoneToDisplay.push(this.assignZoneToCategory(zone, CategoriesNames.N3Zone));
        });
        allZones.robotszone.forEach((zone) => {
          this.zoneToDisplay.push(this.assignZoneToCategory(zone, CategoriesNames.N3Robot));
        });
        allZones.environmentzone.forEach((zone) => {
          this.zoneToDisplay.push(this.assignZoneToCategory(zone, CategoriesNames.N3Environment));
        });
      } catch (e) {
        EventBus.$emit('generalErrorOccurred', {
          message: 'Cannot load data. Try again in a few seconds',
        });
        console.error(e) // eslint-disable-line
      }
      this.loading = false;
    },
    async loadLocations() {
      try {
        this.locations = await fetchLocationForZonesAndDevices();
      } catch (e) {
        EventBus.$emit('generalErrorOccurred', {
          message: 'Cannot load data. Try again in a few seconds',
        });
        console.error(e) // eslint-disable-line
      }
    },
    async loadPartners() {
      try {
        this.partners = await fetchPartners();
      } catch (e) {
        EventBus.$emit('generalErrorOccurred', {
          message: 'Cannot load data. Try again in a few seconds',
        });
        console.error(e); // eslint-disable-line
      }
    },
    loadCategories() {
      this.categories.splice(this.categories.indexOf(CategoriesNames.N3Gateway), 1);
      this.categoriesArray = this.categories.slice();
      this.categoriesArray.push('All');
    },
    editItem(item) {
      this.zoneToEdit = item;
      this.openDialog();
    },
    openDialog() {
      this.dialog = true;
    },
    closeDialog() {
      this.dialog = false;
    },
    confirmDeleteItem(item) {
      if (this.authGuard.isGranted('n3-admin') || this.authGuard.isGranted('n3-superadmin') || this.authGuard.isGranted('n3-admin-mid-leader')) {
        this.zoneToRemove = item;
        this.confirmDialog = true;
      }
    },
    closeConfirmDialog() {
      this.confirmDialog = false;
    },
    update({ updatedZone, category }) {
      const index = this.zoneToDisplay.indexOf(this.zoneToEdit);
      this.zoneToDisplay.splice(index, 1, this.assignZoneToCategory(updatedZone, category));
    },
    create({ createdZone, category }) {
      this.zoneToDisplay.push(this.assignZoneToCategory(createdZone, category));
    },
    deleteItem() {
      const index = this.zoneToDisplay.indexOf(this.zoneToRemove);
      if (index < 0) {
        console.error('This should not happen'); // eslint-disable-line

        return;
      }
      try {
        this.zoneToDisplay.splice(index, 1);
        deleteZone(this.zoneToRemove.id);
        if (this.zoneToRemove instanceof ZoneZone) {
          deleteZone(this.zoneToRemove.id);
        } else if (this.zoneToRemove instanceof RobotZone) {
          deleteRobotZone(this.zoneToRemove.id);
        } else {
          deleteEnvironmentZone(this.zoneToRemove.id);
        }
        this.device = this.zoneToRemove.device;
        this.closeConfirmDialog();
      } catch (error) {
        EventBus.$emit('generalErrorOccurred', {
          message: 'Cannot remove zone',
        });
        this.zoneToDisplay.splice(index, 0, this.zoneToRemove);
        console.error(error); // eslint-disable-line
        this.closeDialog();
      }
    },
    assignZoneToCategory(zone, category) {
      if (category === CategoriesNames.N3Zone) {
        return new ZoneZone(zone);
      } if (category === CategoriesNames.N3Robot) {
        return new RobotZone(zone);
      }

      return new EnvironmentZone(zone);
    },
    redirect(type, item) {
      this.$router.push({ name: `management.${type}`, params: { searchName: `${item}` } });
    },
    customSort(items, index, isDescending) {
      items.sort((a, b) => {
        switch (index[0]) {
          case 'name':
            return compare(a.name, b.name, isDescending);
          case 'device':
            return compare(a.device.name, b.device.name, isDescending);
          case 'device.externalEquipment':
            return compareObjects(a?.device?.externalEquipment, b?.device?.externalEquipment, isDescending);
          case 'device.subcategory':
            return compare(a.device.subcategory, b.device.subcategory, isDescending);
          case 'location':
            return compare(a.location.loc_name, b.location.loc_name, isDescending);
          case 'client':
            return compare(a.client.name, b.client.name, isDescending);
          case 'created':
            return compare(a.created, b.created, isDescending);
          case 'n3partner':
            return compareObjects(a?.n3partner?.name, b?.n3partner?.name, isDescending);
          default: return items;
        }
      });

      return items;
    },
    customSearch(value, search, zones) {
      let toReturn;
      const clientSearched = zones.location.n3client.name.toString().toLowerCase();
      toReturn = headerSearch(value, search, clientSearched);
      if (toReturn === false) {
        const zoneSearched = zones.location.loc_name.toString().toLowerCase();
        toReturn = headerSearch(value, search, zoneSearched);
      }
      if (toReturn === false) {
        const sensorSearched = zones.device.name.toString().toLowerCase();
        toReturn = headerSearch(value, search, sensorSearched);
      }

      return toReturn;
    },

  },
};

</script>
<style lang="scss" scoped>
  table {
    tr {
      .actions {
        .v-icon {
          visibility: hidden;
        }
      }
      &:hover {
        .actions {
          .v-icon {
            visibility: visible;
          }
        }
      }
    }
  }
    .v-select {
    width: 1%;
    justify-content: center;
    margin-top: 25px;
}
</style>
