var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"fill-height"},[_c('ManagementBar',{attrs:{"title":_vm.$tc('common.partner', 2).capitalize(),"listsize":_vm.partners.length},scopedSlots:_vm._u([(_vm.isSuperadmin)?{key:"actions",fn:function(){return [_c('v-btn',{attrs:{"color":"success","small":""},on:{"click":function($event){$event.stopPropagation();return _vm.openDialog($event)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-plus ")]),_vm._v(" "+_vm._s(_vm.$t("entity.new", { entity: _vm.$tc("common.partner", 1) }))+" ")],1)]},proxy:true}:null],null,true)},[_c('v-text-field',{attrs:{"prepend-inner-icon":"mdi-magnify","label":((_vm.$t('entity.find', { entity: _vm.$tc('common.partner', 1) })) + "...").capitalize(),"single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.partners,"search":_vm.search,"loading":_vm.loading,"footer-props":{
      itemsPerPageOptions: [20, 50, 100, -1]
    },"disable-pagination":false,"item-key":"id","fixed-header":"","sort-by":"[name]"},scopedSlots:_vm._u([{key:"item.n3clients",fn:function(ref){
    var item = ref.item;
return _vm._l((item.n3clients),function(client){return _c('span',{key:client.name},[_c('v-chip',[_vm._v(" "+_vm._s(client.name)+" ")])],1)})}},{key:"item.n3devices",fn:function(ref){
    var item = ref.item;
return _vm._l((item.n3devices),function(device){return _c('span',{key:device.name},[_c('v-chip',[_vm._v(" "+_vm._s(device.name)+" ")])],1)})}},{key:"item.partner_has_users",fn:function(ref){
    var item = ref.item;
return _vm._l((item.partner_has_users),function(user){return _c('span',{key:user.n3user.client_id},[_c('v-chip',[_vm._v(" "+_vm._s(user.n3user.email)+" ")])],1)})}},{key:"item.action",fn:function(ref){
    var item = ref.item;
return [_c('span',{staticClass:"actions"},[(_vm.isSuperadmin)?_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){$event.stopPropagation();return _vm.editItem(item)}}},[_vm._v(" mdi-pencil ")]):_vm._e(),(_vm.isSuperadmin)?_c('v-icon',{attrs:{"small":""},on:{"click":function($event){$event.stopPropagation();return _vm.confirmDeleteItem(item)}}},[_vm._v(" mdi-delete ")]):_vm._e()],1)]}}],null,true)}),_c('v-dialog',{attrs:{"max-width":"480px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('ManagementPartnerForm',{attrs:{"partner":_vm.partnerToEdit},on:{"submit":_vm.submit,"close":_vm.closeDialog}})],1),_c('DialogConfirm',{attrs:{"show":_vm.confirmDialog,"danger":true},on:{"cancel":_vm.closeConfirmDialog,"confirm":_vm.deleteItem}},[_vm._v(" "+_vm._s(_vm.$t("confirmation.delete.title"))+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }