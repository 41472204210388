<template>
  <v-card>
    <v-container>
      <v-form ref="form" v-model="valid" novalidate="true" @submit.prevent="handleSubmit">
        <v-card-title>
          <v-row>
            <v-col cols="11">
              <span class="headline">{{ headline.capitalize() }}</span>
            </v-col>
            <v-col cols="1" class="text-right">
              <v-btn icon @click.stop="closeForm">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-text-field
            v-model="name"
            :label="$t('form.label.name')"
            block
            :rules="[rules.required]"
          />
          <v-text-field v-model="externalEquipment" :label="$t('form.label.externalEquipment')" />
          <v-select
            v-model="category"
            :items="categoriesToChoose"
            :disabled="isDisabled('category')"
            :label="$t('form.label.category')"
            block
            :rules="[rules.required]"
            @change="categoryResetValues"
          />
          <v-select
            v-if="category"
            v-model="subcategory"
            :items="subcategoryToChoose"
            :disabled="isDisabled('subcategory')"
            :label="$t('form.label.subcategory')"
            item-text="sub_category_name"
            item-value="sub_category_name"
            hide-no-data
            chips
            :rules="[rules.required]"
            @change="resetValues"
          />
          <v-text-field
            v-model="value"
            :label="$t('form.label.identifier')"
            :rules="[rules.required, valueFormat]"
          />
          <v-row>
            <v-col :cols="7" align-self="center">
              <v-autocomplete
                v-model="locationId"
                :items="locationsToDisplay"
                :disabled="isDisabled('location')"
                item-text="loc_name"
                item-value="loc_id"
                :label="$t('form.label.location')"
                hide-no-data
                chips
                auto-select-first
                :rules="[rules.required]"
                @change="onLocationChange"
              />
            </v-col>
            <v-col :cols="5" align-self="center">
              <v-btn block :disabled="isDisabled('location')" @click.stop="dialog = true">
                {{ $t("form.label.newLocation") }}
              </v-btn>
            </v-col>
          </v-row>
          <v-autocomplete
            v-if="!isPartner"
            v-model="partnerId"
            :items="partnersName"
            item-text="name"
            item-value="partner_id"
            :disabled="disablePartners"
            :label="$t('form.label.partner')"
            chips
            clearable
            auto-select-first
          />
          <div v-if="zoneName !== null && subcategoryWithZone.includes(subcategory) && update">
            <v-text-field
              v-model="zoneName"
              :label="$t('form.label.nameOfTheAssignedZone')"
              :rules="[rules.required, rules.letterOrDigit]"
            />
          </div>
          <div v-if="subcategory === subcategoryN3Connector">
            <v-text-field
              v-model="gpsLatitude"
              :label="$t('form.label.gpslatitude')"
              name="Gpslatitude"
              :rules="[rules.required, rules.gpsCoordinates]"
            />
            <v-text-field
              v-model="gpsLongitude"
              :label="$t('form.label.gpslongitude')"
              name="gpsLongitude"
              :rules="[rules.required, rules.gpsCoordinates]"
            />
            <v-btn @click.stop="openMapDialog = true">
              {{ $t("form.label.mapButton") }}
              <v-icon> mdi-map-marker </v-icon>
            </v-btn>
          </div>
          <ManagementMapContainer
            v-if="openMapDialog"
            :gpslat="parseFloat(gpsLatitude) || 0"
            :gpslng="parseFloat(gpsLongitude) || 0"
            @close="closeMapDialog"
            @submit="submit"
          />
          <v-dialog v-model="dialog" max-width="640px">
            <LocationFormContainer
              :names="locationsNames"
              :coordinates="locationsCoordinates"
              @close="closeDialog"
              @createLocation="createLocation"
            />
          </v-dialog>
        </v-card-text>
        <v-card-actions>
          <v-btn color="success" type="submit" :disabled="!valid" block>
            {{ submitLabel }}
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-container>
  </v-card>
</template>

<script>
import {
  required, number, beaconId, gpsCoordinates, letterOrDigit,
} from '@/validation/rules';
import { changeToDecimal } from '@/helpers/deviceConversions';
import { fetchLocationById } from '@/data';
import { CategoriesNames, SubcategoriesNames, SubcategoriesTypes } from '@/model/CategoriesObjects';
import LocationFormContainer from '@/views/Management/Locations/FormContainer';
import ManagementMapContainer from '../Zones/MapContainer';

const DEFAULT_DEVICE = {
  name: '',
  subcategory: '',
  category: '',
  sensorIdentifier: '',
  externalEquipment: '',
  gpsLatitude: '',
  gpsLongitude: '',
  locationId: '',
  zoneName: '',
  partnerId: '',
};

export default {
  name: 'ManagementDeviceForm',
  components: {
    ManagementMapContainer,
    LocationFormContainer,
  },
  inject: ['authGuard'],
  props: {
    device: {
      type: Object,
      default: null,
    },
    locations: {
      type: Array,
      default: null,
    },
    update: {
      type: Boolean,
      default: true,
    },
    addFromAnotherForm: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      newLocation: null,
      dialog: false,
      categories: CategoriesNames,
      categoriesToChoose: Object.values(CategoriesNames),
      valid: true,
      deviceValues: { ...DEFAULT_DEVICE },
      openMapDialog: false,
      rules: {
        required,
        number,
        beaconId,
        gpsCoordinates,
        letterOrDigit,
      },
      subcategoryWithZone: [
        SubcategoriesNames.Beacon,
        SubcategoriesNames.N3IAQ,
        SubcategoriesNames.N3Robot,
        SubcategoriesNames.N3RobotB,
      ],
      subcategoryN3Connector: SubcategoriesNames.N3Connector,
    };
  },
  computed: {
    isPartner() {
      return this.authGuard.isGranted('n3-partner');
    },
    disablePartners() {
      return this.isPartner || this.locationId === '';
    },
    locationsToDisplay() {
      const locationsArray = Array.from(this.locations);
      if (this.newLocation) {
        locationsArray.unshift(this.newLocation);
      }

      return locationsArray;
    },
    locationsNames() {
      if (this.locations.loc_name) {
        return this.locations.map((e) => e.loc_name.toLowerCase().trim());
      }

      return [];
    },
    partnersName() {
      if (this.locationId !== '') {
        const partners = [];
        const location = this.locationsToDisplay.filter((e) => e.loc_id === this.locationId);
        if (location[0]?.partner_has_locs) { // eslint-disable-line
          location[0].partner_has_locs.forEach((item) => { // eslint-disable-line
            partners.push(item.n3partner);
          });

          return partners;
        }
      }

      return [];
    },
    subcategoryToChoose() {
      return SubcategoriesTypes[this.category];
    },
    valueFormat() {
      if (
        this.subcategory === SubcategoriesNames.Beacon
        || this.subcategory === SubcategoriesNames.N3RobotB
      ) {
        return this.rules.beaconId;
      }

      return this.rules.number;
    },
    headline() {
      if (this.addFromAnotherForm) {
        return this.$t('entity.add', { entity: this.$tc('common.device', 1) });
      }
      if (this.device) {
        return this.$t('entity.update', { entity: this.$tc('common.device', 1) });
      }

      return this.$t('entity.add', { entity: this.$tc('common.device', 1) });
    },
    submitLabel() {
      if (this.addFromAnotherForm) {
        return this.$t('action.create');
      }
      if (this.device) {
        return this.$t('action.update');
      }

      return this.$t('action.create');
    },
    name: {
      get() {
        return this.deviceValues.name;
      },
      set(name) {
        this.deviceValues.name = name;
      },
    },
    subcategory: {
      get() {
        return this.deviceValues.subcategory;
      },
      set(subcategory) {
        this.deviceValues.subcategory = subcategory;
      },
    },
    category: {
      get() {
        return this.deviceValues.category;
      },
      set(category) {
        this.deviceValues.category = category;
      },
    },
    value: {
      get() {
        return this.deviceValues.sensorIdentifier;
      },
      set(value) {
        this.deviceValues.sensorIdentifier = value;
      },
    },
    externalEquipment: {
      get() {
        return this.deviceValues.externalEquipment;
      },
      set(externalEquipment) {
        this.deviceValues.externalEquipment = externalEquipment;
      },
    },
    locationId: {
      get() {
        return this.deviceValues.locationId;
      },
      set(locationId) {
        this.deviceValues.locationId = locationId;
      },
    },
    partnerId: {
      get() {
        if (!this.deviceValues.partnerId && this.authGuard.isGranted('n3-partner')) {
          return this.partnersName[0].partner_id;
        }

        return this.deviceValues.partnerId;
      },
      set(partnerId) {
        if (partnerId === undefined) {
          this.deviceValues.partnerId = null;
        } else {
          this.deviceValues.partnerId = partnerId;
        }
      },
    },
    gpsLatitude: {
      get() {
        return this.deviceValues.gpsLatitude;
      },
      set(gpsLatitude) {
        this.deviceValues.gpsLatitude = gpsLatitude;
      },
    },
    gpsLongitude: {
      get() {
        return this.deviceValues.gpsLongitude;
      },
      set(gpsLongitude) {
        this.deviceValues.gpsLongitude = gpsLongitude;
      },
    },
    zoneName: {
      get() {
        return this.deviceValues.zoneName;
      },
      set(zoneName) {
        this.deviceValues.zoneName = zoneName;
      },
    },
    locationsCoordinates() {
      const locationsCoordinates = [];
      this.locationsToDisplay.forEach((location) => {
        locationsCoordinates.push({
          lat: location.gpsLatitude,
          long: location.gpsLongitude,
          clientId: location.clientIdentifier,
          name: location.name,
        });
      });

      return locationsCoordinates;
    },
  },
  watch: {
    locationId() {
      if (this.category === CategoriesNames.N3Gateway) {
        this.setLatLngFromLocation(this.locationId);
      }
    },
    subcategory() {
      if (this.subcategory === this.subcategoryN3Connector) {
        this.setLatLngFromLocation(this.locationId);
      }
    },
    device(newDevice, oldDevice) {
      if (this.addFromAnotherForm && newDevice !== null) {
        this.deviceValues = { ...this.device };
      } else if (newDevice === null) {
        this.$refs.form.reset();
        this.deviceValues = { ...DEFAULT_DEVICE };
      } else if (newDevice !== oldDevice) {
        this.deviceValues = { ...this.device };
      }
    },
  },
  mounted() {
    if (this.device) {
      this.deviceValues = { ...this.device };
    }
  },
  methods: {
    handleSubmit() {
      this.$emit('submit', {
        name: this.name,
        sensoridvalue:
          this.subcategory === SubcategoriesNames.Beacon
          || this.subcategory === SubcategoriesNames.N3RobotB
            ? changeToDecimal(this.value)
            : this.value,
        locationId: this.locationId,
        categoryname: this.category,
        subcategoryname: this.subcategory,
        gpsLatitude: this.gpsLatitude,
        gpsLongitude: this.gpsLongitude,
        externalEquipment: this.externalEquipment,
        zoneName: this.zoneName,
        partnerId: this.partnerId,
      });
    },
    resetComponent() {
      if (this.$refs.form) this.$refs.form.reset();
    },
    createLocation(location) {
      this.newLocation = location;
      this.locationId = location.loc_id;
    },
    closeDialog() {
      this.dialog = false;
    },
    closeForm() {
      this.$emit('close');
    },
    resetValues() {
      this.value = '';
      this.gpsLatitude = '';
      this.gpsLongitude = '';
    },
    categoryResetValues() {
      this.subcategory = '';
      this.resetValues();
    },
    closeMapDialog() {
      this.openMapDialog = false;
    },
    submit(submitted) {
      this.gpsLatitude = submitted.lat;
      this.gpsLongitude = submitted.lng;
      this.closeMapDialog();
    },
    isDisabled(fieldName) {
      if (this.update || this.addFromAnotherForm) {
        return this.disabled.includes(fieldName);
      }

      return false;
    },
    async setLatLngFromLocation(locationId) {
      const fetchedLocation = await fetchLocationById(locationId);
      this.gpsLatitude = fetchedLocation[0].gps_latitude;
      this.gpsLongitude = fetchedLocation[0].gps_longitude;
    },
    onLocationChange() {
      this.deviceValues.partnerId = null;
    },
  },
};
</script>
