var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"fill-height"},[_c('ManagementBar',{attrs:{"title":_vm.$tc('common.zone', 2).capitalize(),"listsize":_vm.zones.length},scopedSlots:_vm._u([(_vm.isAdmin)?{key:"actions",fn:function(){return [_c('v-btn',{attrs:{"color":"success","small":""},on:{"click":function($event){$event.stopPropagation();return _vm.openDialog($event)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-plus ")]),_vm._v(" "+_vm._s(_vm.$t('entity.new', { entity: _vm.$tc('common.zone', 1) }))+" ")],1)]},proxy:true}:null],null,true)},[_c('v-text-field',{attrs:{"prepend-inner-icon":"mdi-magnify","label":((_vm.$t('entity.find', { entity: _vm.$tc('common.zone', 1) })) + "...").capitalize(),"single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-select',{staticClass:"v-select",attrs:{"flat":"","solo":"","loading":_vm.loading,"items":_vm.categoriesArray,"item-text":"category_name","item-value":"category_id","label":"Category","hide-no-data":""},model:{value:(_vm.category),callback:function ($$v) {_vm.category=$$v},expression:"category"}}),(_vm.isSuperAdmin)?_c('v-select',{staticClass:"v-select",attrs:{"loading":_vm.loading,"items":_vm.partners,"item-text":"name","item-value":"partner_id","flat":"","solo":"","label":_vm.$t('common.partner'),"clearable":""},model:{value:(_vm.selectedPartner),callback:function ($$v) {_vm.selectedPartner=$$v},expression:"selectedPartner"}}):_vm._e()],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.zones,"search":_vm.search,"loading":_vm.loading,"item-key":"key","footer-props":{
      itemsPerPageOptions:[20, 50, 100, -1]
    },"disable-pagination":false,"custom-sort":_vm.customSort,"custom-filter":_vm.customSearch,"fixed-header":""},scopedSlots:_vm._u([{key:"loading",fn:function(){return [_c('v-skeleton-loader',{staticClass:"mx-auto",attrs:{"max-width":"80%","type":"table-row@3"}})]},proxy:true},{key:"no-data",fn:function(){return [_c('v-card-text',{staticClass:"pa-8"},[_c('v-icon',{staticClass:"mb-6",attrs:{"slot":"icon","size":"64"},slot:"icon"},[_vm._v(" mdi-city ")]),_c('p',{staticClass:"display-1 text--primary"},[_vm._v(" "+_vm._s(_vm.$t('entity.empty.headline', { entities: _vm.$tc('common.zone', 2) }))+" ")]),_c('p',[_vm._v(_vm._s(_vm.$t('entity.empty.description', { entities: _vm.$tc('common.zone', 2) })))]),_c('v-btn',{attrs:{"tile":"","color":"blue lighten-4"},on:{"click":_vm.openDialog}},[_vm._v(" "+_vm._s(_vm.$t('entity.add', { entity: _vm.$tc('common.zone', 1) }))+" ")])],1)]},proxy:true},{key:"item.client",fn:function(ref){
    var item = ref.item;
return [(item.client)?_c('v-chip',{attrs:{"color":"white"},on:{"click":function($event){return _vm.redirect('clients', item.client.name)}}},[_vm._v(" "+_vm._s(item.client.name)+" ")]):_vm._e()]}},{key:"item.location",fn:function(ref){
    var item = ref.item;
return [(item.location)?_c('v-chip',{attrs:{"color":"white"},on:{"click":function($event){return _vm.redirect('locations', item.location.loc_name)}}},[_vm._v(" "+_vm._s(item.location.loc_name)+" ")]):_vm._e()]}},{key:"item.device",fn:function(ref){
    var item = ref.item;
return [_c('v-chip',{attrs:{"color":"white"},on:{"click":function($event){return _vm.redirect('devices', item.device.name)}}},[_vm._v(" "+_vm._s(item.device.name)+" ")])]}},{key:"item.n3partner",fn:function(ref){
    var item = ref.item;
return [(item.n3partner)?_c('v-chip',{attrs:{"color":"white"},on:{"click":function($event){(_vm.isSuperAdmin) ? _vm.redirect('partners', item.n3partner.name) : function () {}}}},[_vm._v(" "+_vm._s(item.n3partner.name)+" ")]):_vm._e()]}},{key:"item.gpsLatitude",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(item.gpsLatitude)+", "+_vm._s(item.gpsLongitude)+" ")]}},{key:"item.created",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("dateWithTime")(_vm._f("gmtTime")(item.created)))+" ")]}},{key:"item.sendCleanPilot",fn:function(ref){
    var item = ref.item;
return [(item.sendCleanPilot)?_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v(" mdi-check-circle ")]):_vm._e()]}},{key:"item.action",fn:function(ref){
    var item = ref.item;
return [_c('span',{staticClass:"actions"},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){$event.stopPropagation();return _vm.editItem(item)}}},[_vm._v(" mdi-pencil ")]),(_vm.isAdmin)?_c('v-icon',{attrs:{"small":""},on:{"click":function($event){$event.stopPropagation();return _vm.confirmDeleteItem(item)}}},[_vm._v(" mdi-delete ")]):_vm._e()],1)]}}],null,true)}),_c('v-dialog',{attrs:{"max-width":"1000"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('FormContainer',{attrs:{"zone":_vm.zoneToEdit,"locations":_vm.locations,"disabled":_vm.disabledInputs,"device":_vm.device},on:{"update":_vm.update,"create":_vm.create,"close":_vm.closeDialog}})],1),_c('DialogConfirm',{attrs:{"show":_vm.confirmDialog,"danger":true},on:{"cancel":_vm.closeConfirmDialog,"confirm":_vm.deleteItem}},[_vm._v(" "+_vm._s(_vm.$t('confirmation.delete.title'))+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }