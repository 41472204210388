<template>
  <div>
    <div>
      <v-text-field
        v-model="environmentModel.minTemperature"
        class="leftblock"
        :label="$t('form.label.minTemperature')"
        name="minTemperature"
        :rules="[rules.required, rules.number, rules.minMax(environmentModel.minTemperature,
                                                            environmentModel.maxTemperature)]"
        @input="notify"
      />
      <v-text-field
        v-model="environmentModel.maxTemperature"
        class="rightblock"
        :label="$t('form.label.maxTemperature')"
        name="maxTemperature"
        :rules="[rules.required, rules.number]"
        @input="notify"
      />
    </div>
    <div>
      <v-text-field
        v-model="environmentModel.minHumidity"
        class="leftblock"
        :label="$t('form.label.minHumidity')"
        name="minHumidity"
        :rules="[rules.required, rules.number, rules.minMax(environmentModel.minHumidity,
                                                            environmentModel.maxHumidity)]"
        @input="notify"
      />
      <v-text-field
        v-model="environmentModel.maxHumidity"
        class="rightblock"
        :label="$t('form.label.maxHumidity')"
        name="maxHumidity"
        :rules="[rules.required, rules.number]"
        @input="notify"
      />
    </div>
    <div>
      <v-text-field
        v-model="environmentModel.minCo2"
        class="leftblock"
        :label="$t('form.label.minCo2')"
        name="minCo2"
        disabled
        :rules="[rules.required, rules.number, rules.minMax(environmentModel.minCo2,
                                                            environmentModel.maxCo2)]"
        @input="notify"
      />
      <v-text-field
        v-model="environmentModel.maxCo2"
        class="rightblock"
        :label="$t('form.label.maxCo2')"
        name="maxCo2"
        :rules="[rules.required, rules.number]"
        @input="notify"
      />
    </div>
    <div>
      <v-text-field
        v-model="environmentModel.minDust"
        class="leftblock"
        :label="$t('form.label.minDust')"
        name="minDust"
        disabled
        :rules="[rules.required, rules.number, rules.minMax(environmentModel.minDust,
                                                            environmentModel.maxDust)]"
        @input="notify"
      />
      <v-text-field
        v-model="environmentModel.maxDust"
        class="rightblock"
        :label="$t('form.label.maxDust')"
        name="maxDust"
        :rules="[rules.required, rules.number]"
        @input="notify"
      />
    </div>
    <div>
      <v-text-field
        v-model="environmentModel.min_surface_dust"
        class="leftblock"
        :label="$t('form.label.minSurfaceDust')"
        name="minSurfaceDust"
        disabled
      />
      <v-text-field
        v-model="environmentModel.max_surface_dust"
        class="rightblock"
        :label="$t('form.label.maxSurfaceDust')"
        name="maxSurfaceDust"
        disabled
      />
    </div>
    <div>
      <v-text-field
        v-model="environmentModel.floorId"
        class="leftblock"
        :label="$t('form.label.floorId')"
        name="floorId"
        :rules="[rules.required, rules.number]"
        @input="notify"
      />
      <v-text-field
        v-model="environmentModel.roomId"
        class="rightblock"
        :label="$t('form.label.roomId')"
        name="roomId"
        :rules="[rules.required, rules.number]"
        @input="notify"
      />
    </div>
  </div>
</template>

<script>
import { required, number, minMax } from '@/validation/rules';

const DEFAULT_ENVIRONMENT = {
  minTemperature: '17',
  maxTemperature: '24',
  minHumidity: '20',
  maxHumidity: '60',
  minCo2: '0',
  maxCo2: '1000',
  minDust: '0',
  maxDust: '35',
  min_surface_dust: '0',
  max_surface_dust: '5',
  floorId: '',
  roomId: '',
};

export default {
  name: 'EnvironmentFormComponent',
  props: {
    zone: {
      type: Object,
      default: null,
    },
    addFromAnotherForm: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      environmentModel: { ...DEFAULT_ENVIRONMENT },
      rules: {
        required,
        number,
        minMax,
      },
    };
  },
  watch: {
    zone(value) {
      if (value) {
        this.environmentModel = { ...value };
      } else {
        this.environmentModel = { ...DEFAULT_ENVIRONMENT };
        this.$refs.form.reset();
      }
    },
  },
  created() {
    this.environmentModel = this.zone === null || this.addFromAnotherForm ? ({ ...DEFAULT_ENVIRONMENT })
      : this.computeEnvironmentModel();
  },
  mounted() {
    this.environmentModel = this.zone === null || this.addFromAnotherForm ? ({ ...DEFAULT_ENVIRONMENT })
      : this.computeEnvironmentModel();
    this.notify();
  },
  methods: {
    notify() {
      this.$emit('updated', this.environmentModel);
    },
    computeEnvironmentModel() {
      // @TODO delete this after add surface_dust to DB
      const computedModel = { ...this.zone };
      computedModel.min_surface_dust = DEFAULT_ENVIRONMENT.min_surface_dust;
      computedModel.max_surface_dust = DEFAULT_ENVIRONMENT.max_surface_dust;
      
      return computedModel;
    },
  },
};
</script>

<style scoped>
.rightblock {
  width:45%;
  display: inline-block;
  margin:5%;
  margin-top: 10px;
  margin-bottom: 0px;
}
.leftblock {
  margin-top: 10px;
  margin-bottom: 0px;
  width:45%;
  display: inline-block;
}
</style>
