<template>
  <div>
    <v-card>
      <v-dialog v-model="visible" width="815px">
        <v-card class="containerMap">
          <v-container>
            <v-row v-if="enableAlert">
              <v-alert
                type="error"
                class="text-left"
                dismissible
                width="100%"
              >
                {{ $t('setZoneMap.error') }}  {{ status }}
              </v-alert>
            </v-row>
            <v-row class="rowclass">
              <v-col cols="9">
                <span>
                  <v-text-field
                    v-model="address"
                    :label="$t('form.label.writeAddress')"
                    prepend-inner-icon="mdi-magnify"
                    solo
                    flat
                    single-line
                    hide-details
                    @keyup.enter="geocodeLocation"
                  />
                </span>
              </v-col>
              <v-col cols="3" class="text-right">
                <v-btn color="primary" @click="geocodeLocation">
                  {{ $t('form.label.findPlace') }}
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
          <div class="zones-map">
            <Map
              :max-markers="1"
              :allow-add="true"
              :allow-drag="true"
              :locations.sync="markers"
            />
          </div>
          <v-btn
            color="primary"
            block
            @click="submit"
          >
            {{ $t('action.confirmAndProceed') }}
          </v-btn>
        </v-card>
      </v-dialog>
    </v-card>
  </div>
</template>

<script>

import { gmapInit } from '@/integration/google';
import Map from '@/components/Map/Map';

export default {
  name: 'ManagementMapContainer',
  components: {
    Map,
  },
  props: {
    gpslat: {
      type: Number,
      required: true,
    },
    gpslng: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      visible: false,
      googleMap: null,
      address: '',
      status: '',
      enableAlert: false,
      markers: [],
    };
  },
  watch: {
    visible(newVal, oldVal) {
      if (oldVal === true && newVal === false) {
        this.close();
      }
    },
  },
  async mounted() {
    try {
      this.googleMap = await gmapInit();
    } catch (error) {
      console.error(error); // eslint-disable-line
      throw new Error('Map init failed');
    }
    this.geocoder = new this.googleMap.maps.Geocoder();
    this.visible = true;
  },
  created() {
    if (this.gpslat !== 0 && this.gpslng !== 0) {
      this.initMarker(this.gpslat, this.gpslng);
    }
  },
  methods: {
    initMarker(lat, lng) {
      this.markers = [{ lat: parseFloat(lat), lng: parseFloat(lng) }];
    },
    addressFound(results, status) {
      if (status === 'OK') {
        this.initMarker(results[0].geometry.location.lat(), results[0].geometry.location.lng());
      } else {
        this.geocodeWarn({ status });
      }
    },
    geocodeLocation() {
      this.enableAlert = false;
      this.geocoder.geocode({ address: this.address }, this.addressFound);
    },
    close() {
      this.address = '';
      this.markers = [];
      this.$emit('close');
    },
    submit() {
      this.address = '';
      const { lat, lng } = this.markers[0];
      this.$emit('submit', { lat, lng });
    },
    geocodeWarn(submitted) {
      this.enableAlert = true;
      this.status = submitted.status;
    },
  },
};
</script>

<style lang="scss" scoped>
  .containerMap {
    height: 700px;
    width: 815px;
    .zones-map {
      height: 84%;
    }
  }

  .rowclass {
    height: 50px;
  }
</style>
