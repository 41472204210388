import axios from 'axios';
import { tokenStorage } from '@/security/TokenStorage';

function getAuthorizationHeader(token) {
  return `Bearer ${token}`;
}

export async function testIntegration(roomId, serverUrl) {
  const token = await tokenStorage.getToken();
  let apiResponse = {};

  await axios
    .post('/api/integration/n3zones/test', { room_id: roomId.trim(), target_url: serverUrl.trim() }, {
      headers: { Authorization: getAuthorizationHeader(token) },
    })
    .then((response) => {
      apiResponse = response;
    })
    .catch((error) => {
      if (error.response) {
        apiResponse = error.response;
      } else if (error.request) {
        // console.log(error.request);
      } else {
        // console.log(error.message);
      }
    });

  return apiResponse;
}
