<template>
  <div>
    <v-snackbar
      v-model="showWarning"
      top
      right
      color="warning"
      :timeout="100000"
    >
      {{ $t('common.coordinatesWarning') }}{{ closeLocationName }}
      <v-btn text dark @click.native="showWarning = false">
        {{ $t('common.close') }}
      </v-btn>
    </v-snackbar>
    <v-card>
      <v-container>
        <v-form
          ref="form"
          v-model="valid"
          novalidate="true"
          @submit.prevent="handleSubmit"
        >
          <v-card-title>
            <v-row>
              <v-col cols="11">
                <span class="headline">{{ headline.capitalize() }}</span>
              </v-col>
              <v-col cols="1" class="text-right">
                <v-btn icon @click.stop="closeForm">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-card-title>
          <v-card-text>
            <v-text-field
              id="name"
              v-model="name"
              :disabled="isDisabled('name')"
              :label="$t('form.label.name')"
              name="name"
              :rules="[rules.required]"
            />
            <v-text-field
              v-model="address"
              :label="$t('form.label.address')"
              name="address"
              @blur="fillInGps"
            />
            <v-autocomplete
              v-model="clientIdentifier"
              :disabled="isDisabled('client')"
              :items="clients"
              item-text="name"
              item-value="id"
              :label="$t('form.label.client')"
              hide-no-data
              auto-select-first
              :rules="[rules.required]"
              @change="onClientChange"
            />
            <v-text-field
              v-model="gpsLatitude"
              :label="$t('form.label.gpslatitude')"
              name="Gpslatitude"
              :rules="[rules.required, rules.gpsCoordinates]"
              @input="checkCoordinates"
            />
            <v-text-field
              v-model="gpsLongitude"
              :label="$t('form.label.gpslongitude')"
              name="gpsLongitude"
              :rules="[rules.required, rules.gpsCoordinates]"
              @input="checkCoordinates"
            />
            <v-autocomplete
              v-if="!isPartner"
              v-model="partnersIdentifier"
              :disabled="disablePartners"
              :items="allowedPartners"
              :label="$t('form.label.partners')"
              item-text="name"
              item-value="partner_id"
              hide-no-data
              chips
              clearable
              multiple
              auto-select-first
              :search-input.sync="searchInputPartners"
              @input="searchInputPartners=null"
            />
            <v-btn
              @click.stop="openMapDialog = true"
            >
              {{ $t('form.label.mapButton') }}
              <v-icon> mdi-map-marker </v-icon>
            </v-btn>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn
              color="success"
              type="submit"
              :disabled="!valid"
              block
            >
              {{ submitLabel }}
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-container>
    </v-card>
    <ManagementMapContainer
      v-if="openMapDialog"
      :gpslat="parseFloat(gpsLatitude) || 0"
      :gpslng="parseFloat(gpsLongitude) || 0"
      @close="closeMapDialog"
      @submit="submit"
    />
  </div>
</template>

<script>

import { required, gpsCoordinates } from '@/validation/rules';
import ManagementMapContainer from '@/views/Management/Zones/MapContainer';
import { gmapInit } from '@/integration/google';
import checkIfPointsAreToClose from '@/helpers/checkIfPointsAreToClose';

const DEFAULT_LOCATION = {
  name: '',
  address: '',
  clientIdentifier: '',
  gpsLongitude: '',
  gpsLatitude: '',
  partnersIds: [],
};

export default {
  name: 'ManagmentLocationForm',
  components: {
    ManagementMapContainer,
  },
  inject: ['authGuard'],
  props: {
    location: {
      type: Object,
      default: null,
    },
    partner: {
      type: Array,
      default: null,
    },
    clients: {
      type: Array,
      default: null,
    },
    coordinates: {
      type: Array,
      default: null,
    },
    disabled: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      locationValue: { ...DEFAULT_LOCATION },
      valid: true,
      openMapDialog: false,
      geocoder: null,
      rules: {
        required,
        gpsCoordinates,
      },
      showWarning: false,
      closeLocationName: '',
      searchInputPartners: null,
      oldPartners: [],
    };
  },
  computed: {
    headline() {
      if (this.location) {
        return this.$t('entity.update', { entity: this.$tc('common.location', 1) });
      }

      return this.$t('entity.add', { entity: this.$tc('common.location', 1) });
    },
    submitLabel() {
      if (this.location) {
        return this.$t('action.update');
      }

      return this.$t('action.create');
    },
    allowedPartners() {
      if (this.authGuard.user.role === 'n3-partner' && this.clients.length) {
        return this.clients[0].partner_has_clis.map((e) => e.n3partner);
      }
      const client = this.clients.find((e) => e.id === this.clientIdentifier);
      if (client) {
        return client.partner_has_clis.map((e) => e.n3partner);
      }

      return [];
    },
    isPartner() {
      return this.authGuard.user.role === 'n3-partner';
    },
    disablePartners() {
      return this.isPartner || !this.clientIdentifier;
    },
    name: {
      get() {
        return this.locationValue.name;
      },
      set(name) {
        this.locationValue.name = name;
      },
    },
    address: {
      get() {
        return this.locationValue.address;
      },
      set(address) {
        this.locationValue.address = address;
      },
    },
    gpsLatitude: {
      get() {
        return this.locationValue.gpsLatitude;
      },
      set(gpsLatitude) {
        this.locationValue.gpsLatitude = gpsLatitude;
      },
    },
    gpsLongitude: {
      get() {
        return this.locationValue.gpsLongitude;
      },
      set(gpsLongitude) {
        this.locationValue.gpsLongitude = gpsLongitude;
      },
    },
    clientIdentifier: {
      get() {
        return this.locationValue.clientIdentifier;
      },
      set(clientIdentifier) {
        this.locationValue.clientIdentifier = clientIdentifier;
      },
    },
    partnersIdentifier: {
      get() {
        return this.locationValue.partnersIds;
      },
      set(partners) {
        this.locationValue.partnersIds = partners;
      },
    },
  },
  watch: {
    location(newLocation, oldLocation) {
      if (newLocation == null) {
        this.$refs.form.reset();
        this.locationValue = { ...DEFAULT_LOCATION };
        this.oldPartners = [];
      } else if (newLocation !== oldLocation) {
        this.locationValue = { ...this.location };
        this.oldPartners = this.locationValue.partnersIds ? this.locationValue.partnersIds : [];
      }
    },
  },
  async mounted() {
    if (this.location) {
      this.locationValue = { ...this.location };
      this.oldPartners = this.locationValue.partnersIds ? this.locationValue.partnersIds : [];
    }
    try {
      this.googleMap = await gmapInit();
    } catch (error) {
      console.error(error); // eslint-disable-line
      throw new Error('Map init failed');
    }
    this.geocoder = new this.googleMap.maps.Geocoder();
  },
  methods: {
    isDisabled(fieldName) {
      return this.disabled.includes(fieldName);
    },
    fillInGps() {
      if (!this.gpsLatitude && !this.gpsLongitude && this.address !== null) {
        this.geocoder.geocode({ address: this.address }, this.addressFound);
      }
    },
    addressFound(results, status) {
      if (status === 'OK') {
        this.gpsLatitude = results[0].geometry.location.lat();
        this.gpsLongitude = results[0].geometry.location.lng();
        this.checkCoordinates();
      }
    },
    setAddress(results, status) {
      if (status === 'OK') {
        this.address = results[0].formatted_address;
      }
    },
    checkAndSetAddress() {
      if (this.address === '' || !this.address) {
        const latlng = { lat: parseFloat(this.gpsLatitude), lng: parseFloat(this.gpsLongitude) };
        this.geocoder.geocode({ location: latlng }, this.setAddress);
      }
    },
    handleSubmit() {
      if (this.authGuard.user.role === 'n3-partner') {
        this.locationValue.partnersIds = this.partner;
      }
      const partnersToDelete = this.oldPartners.filter((e) => !this.partnersIdentifier.includes(e));
      const partnersToAdd = this.partnersIdentifier.filter((e) => !this.oldPartners.includes(e));
      this.$emit('submit', {
        name: this.name,
        address: this.address,
        clientId: this.clientIdentifier,
        gpsLatitude: this.gpsLatitude,
        gpsLongitude: this.gpsLongitude,
        partnersToAdd,
        partnersToDelete,
      });
    },
    resetComponent() {
      if (this.$refs.form) {
        this.$refs.form.reset();
        this.oldPartners = [];
      }
    },
    submit(submitted) {
      this.gpsLatitude = submitted.lat;
      this.gpsLongitude = submitted.lng;
      this.checkAndSetAddress();
      this.closeMapDialog();
      this.checkCoordinates();
    },
    closeForm() {
      this.$emit('close');
      this.$refs.form.reset();
      this.oldPartners = [];
    },
    closeMapDialog() {
      this.openMapDialog = false;
    },
    onClientChange() {
      this.partnersIdentifier = [];
      this.checkCoordinates();
    },
    checkCoordinates() {
      this.showWarning = false;
      if (this.clientIdentifier && this.gpsLongitude && this.gpsLatitude) {
        this.coordinates.forEach((location) => {
          if (!this.showWarning && location.clientId === this.clientIdentifier) {
            this.showWarning = checkIfPointsAreToClose(location, {
              lat: parseFloat(this.gpsLatitude),
              long: parseFloat(this.gpsLongitude),
            }, 200);
            this.closeLocationName = (this.showWarning) ? location.name : '';
          }
        });
      }
    },
  },
};
</script>
