var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"fill-height"},[_c('ManagementBar',{attrs:{"title":_vm.$tc('common.user', 2).capitalize(),"listsize":_vm.users.length},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('v-btn',{attrs:{"color":"success","small":""},on:{"click":function($event){$event.stopPropagation();return _vm.openDialog($event)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-plus ")]),_vm._v(" "+_vm._s(_vm.$t("entity.new", { entity: _vm.$tc("common.user", 1) }))+" ")],1)]},proxy:true}])},[_c('v-text-field',{attrs:{"prepend-inner-icon":"mdi-magnify","label":((_vm.$t('entity.find', { entity: _vm.$tc('common.user', 1) })) + "...").capitalize(),"single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),(_vm.isSuperAdmin)?_c('v-select',{staticClass:"v-select",attrs:{"loading":_vm.loading,"items":_vm.partners,"item-text":"name","item-value":"partner_id","flat":"","solo":"","label":_vm.$t('common.partner'),"clearable":""},model:{value:(_vm.selectedPartner),callback:function ($$v) {_vm.selectedPartner=$$v},expression:"selectedPartner"}}):_vm._e()],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.usersToDisplay,"search":_vm.search,"custom-filter":_vm.customSearch,"loading":_vm.loading,"expanded":_vm.expanded,"footer-props":{
      itemsPerPageOptions: [40, 80, 160, -1]
    },"disable-pagination":false,"item-key":"user_id","fixed-header":""},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"item.user_has_clis",fn:function(ref){
    var item = ref.item;
return [(item.user_has_clis[0])?_c('span',[_vm._v(" "+_vm._s(item.user_has_clis[0].n3client.name)+" ")]):_vm._e(),(item.user_has_clis[1])?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.expandRow(item)}}},[(_vm.expanded.includes(item))?_c('v-icon',{attrs:{"size":"16"}},[_vm._v(" mdi-chevron-up ")]):_c('span',{staticClass:"caption"},[_vm._v(" "+_vm._s(item.user_has_clis.length - 1)+"+ ")])],1):_vm._e()]}},{key:"item.user_has_loc",fn:function(ref){
    var item = ref.item;
return [(item.user_has_loc[0] && _vm.isAdmin)?_c('span',[_vm._v(" "+_vm._s(item.user_has_loc[0].n3location.loc_name)+" ")]):_vm._e(),(item.user_has_loc[1] && _vm.isAdmin)?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.expandRow(item)}}},[(_vm.expanded.includes(item))?_c('v-icon',{attrs:{"size":"16"}},[_vm._v(" mdi-chevron-up ")]):_c('span',{staticClass:"caption"},[_vm._v(" "+_vm._s(item.user_has_loc.length - 1)+"+ ")])],1):_vm._e()]}},{key:"item.partners",fn:function(ref){
    var item = ref.item;
return [(item.partners && item.partners[0] && _vm.isAdmin)?_c('span',[_vm._v(" "+_vm._s(item.partners[0].name)+" ")]):_vm._e(),(item.partners && item.partners[1] && _vm.isAdmin)?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.expandRow(item)}}},[(_vm.expanded.includes(item))?_c('v-icon',{attrs:{"size":"16"}},[_vm._v(" mdi-chevron-up ")]):_c('span',{staticClass:"caption"},[_vm._v(" "+_vm._s(item.partners.length - 1)+"+ ")])],1):_vm._e()]}},{key:"expanded-item",fn:function(ref){
    var item = ref.item;
return [_c('td',{attrs:{"colspan":2}}),_c('td',{attrs:{"colspan":3}},[_c('p',{staticClass:"mt-4 mb-4"},_vm._l((item.user_has_clis),function(user){return _c('span',{key:user.n3client.name},[_c('li',[_vm._v(" "+_vm._s(user.n3client.name)+" ")])])}),0)]),(_vm.isAdmin)?_c('td',{attrs:{"colspan":3}},[_c('p',{staticClass:"mt-4 mb-4"},_vm._l((item.user_has_loc),function(user){return _c('span',{key:user.n3location.loc_name},[_c('li',[_vm._v(" "+_vm._s(user.n3location.loc_name)+" ")])])}),0)]):_vm._e(),(_vm.isAdmin)?_c('td',{attrs:{"colspan":2}},[_c('p',{staticClass:"mt-4 mb-4"},_vm._l((item.partners),function(partner){return _c('span',{key:partner.name},[_c('li',[_vm._v(" "+_vm._s(partner.name)+" ")])])}),0)]):_vm._e()]}},{key:"item.email",fn:function(ref){
    var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.email)+" ")])]}},{key:"item.email_confirmed",fn:function(ref){
    var item = ref.item;
return [_c('span',[(item.email_confirmed)?_c('v-icon',[_vm._v("mdi-account-check-outline")]):_vm._e()],1)]}},{key:"item.n3funct.fun_name",fn:function(ref){
    var item = ref.item;
return [(item.n3funct === null)?_c('span',[_vm._v(" n3-superadmin ")]):_c('span',[_vm._v(" "+_vm._s(item.n3funct.fun_name)+" ")])]}},{key:"loading",fn:function(){return [_c('v-skeleton-loader',{staticClass:"mx-auto",attrs:{"max-width":"80%","type":"table-row@3"}})]},proxy:true},{key:"no-data",fn:function(){return [_c('v-card-text',{staticClass:"pa-8"},[_c('v-icon',{staticClass:"mb-6",attrs:{"slot":"icon","size":"64"},slot:"icon"},[_vm._v(" mdi-city ")]),_c('p',{staticClass:"display-1 text--primary"},[_vm._v(" "+_vm._s(_vm.$t("entity.empty.headline", { entities: _vm.$tc("common.user", 2) }))+" ")]),_c('p',[_vm._v(" "+_vm._s(_vm.$t("entity.empty.description", { entities: _vm.$tc("common.user", 2) }))+" ")]),_c('v-btn',{attrs:{"tile":"","color":"blue lighten-4"},on:{"click":_vm.addUser}},[_vm._v(" "+_vm._s(_vm.$t("entity.add", { entity: _vm.$tc("common.user", 1) }))+" ")])],1)]},proxy:true},{key:"item.action",fn:function(ref){
    var item = ref.item;
return [(item.n3funct !== null && _vm.usersAllowToEdit.includes(item.n3funct.fun_name))?_c('span',{staticClass:"actions"},[_c('v-icon',{attrs:{"small":""},on:{"click":function($event){$event.stopPropagation();return _vm.editItem(item)}}},[_vm._v(" mdi-pencil ")]),(item.n3funct.fun_name !== 'n3-superadmin')?_c('v-icon',{attrs:{"small":""},on:{"click":function($event){$event.stopPropagation();return _vm.confirmDeleteItem(item)}}},[_vm._v(" mdi-delete ")]):_vm._e(),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){$event.stopPropagation();return _vm.sendAgainActivationMail(item)}}},[_vm._v(" mdi-email-outline ")])],1):_vm._e()]}}],null,true)}),_c('v-dialog',{attrs:{"max-width":"560px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('UserForm',{attrs:{"user":_vm.userToEdit,"clients":_vm.clients,"functs":_vm.functsAllowedtoAdd,"partners":_vm.partners},on:{"submit":_vm.submit,"close":_vm.closeDialog}})],1),_c('DialogConfirm',{attrs:{"show":_vm.confirmDialog,"danger":true},on:{"cancel":_vm.closeConfirmDialog,"confirm":_vm.deleteItem}},[_vm._v(" "+_vm._s(_vm.$t("confirmation.delete.title"))+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }