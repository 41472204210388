export function changeToHex(value) {
  const decimalValue = parseInt(value, 10);
  const hexValue = decimalValue.toString(16).toUpperCase();

  return hexValue;
}

export function changeToDecimal(sensoridvalue) {
  const adjustvalue = `0x${sensoridvalue}`;
  const newSensoridvalue = parseInt(adjustvalue, 16);
    
  return newSensoridvalue;
}
