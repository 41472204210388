<template>
  <v-card>
    <v-container>
      <v-form
        ref="form"
        novalidate="true"
        @submit.prevent="handleSubmit"
      >
        <v-card-title>
          <v-row>
            <v-col cols="11">
              <span class="headline">{{ $t('entity.add', { entity: $tc('common.notification', 1) }).capitalize() }}</span>
            </v-col>
            <v-col cols="1" class="text-right">
              <v-btn icon @click.stop="closeForm">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text>
          <div>
            <v-autocomplete
              v-model="user"
              :items="users"
              item-text="email"
              item-value="email"
              :label="$t('form.label.user')"
              single-line
              hide-no-data
              chips
              :rules="[rules.required]"
              return-object
              @change="loadLocations(user)"
            />
          </div>
          <div v-if="user">
            <v-autocomplete
              v-model="selectedLocations"
              :items="locations"
              item-text="loc_name"
              item-value="loc_id"
              :label="$t('form.label.location')"
              multiple
              chips
              :rules="[rules.required]"
            />
            <v-autocomplete
              v-if="isSuperAdmin"
              v-model="partnerId"
              :items="partners"
              :label="$t('form.label.partner')"
              item-text="name"
              item-value="partner_id"
              hide-no-data
              chips
              clearable
              auto-select-first
            />
          </div>
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="success"
            type="submit"
            :disabled="!valid"
            block
          >
            {{ $t('action.create') }}
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-container>
  </v-card>
</template>

<script>
import { required } from '@/validation/rules';

export default {
  name: 'ManagementNotificationForm',
  inject: ['authGuard'],
  props: {
    users: {
      type: Array,
      default: null,
    },
    partner: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      userName: '',
      selectedLocations: [],
      user: '',
      partnerId: null,
      locations: [],
      rules: {
        required,
      },

    };
  },
  computed: {
    valid() {
      return !!this.selectedLocations.length;
    },
    isSuperAdmin() {
      return this.authGuard.user.role === 'n3-superadmin';
    },
    isPartner() {
      return this.authGuard.user.role === 'n3-partner';
    },
    partners() {
      const [selectedUser] = this.users.filter((e) => e.email === this.user.email);
      if (selectedUser) {
        if (selectedUser.n3funct.fun_name === 'n3-partner') {
          return [selectedUser.n3partner];
        }

        return selectedUser.partner_has_users.map((e) => e.n3partner);
      }

      return [];
    },
  },
  methods: {
    loadLocations(user) {
      if (user !== undefined) {
        this.selectedLocations = [];
        this.locations = [];
        this.partnerId = null;
        this.userName = user.full_name;
        if (user.n3client !== null) {
          user.n3client.n3locations.forEach((location) => {
            this.locations.push(location);
          });
        }
        if (user.user_has_clis !== null) {
          user.user_has_clis.forEach((userhascli) => {
            if (userhascli.n3client.n3locations.length > 0) {
              userhascli.n3client.n3locations.forEach((location) => {
                this.locations.push(location);
              });
            }
          });
        }
      }
    },
    resetVar() {
      this.selectedLocations = [];
      this.email = '';
      this.userName = '';
      this.partnerId = null;
      this.$refs.form.reset();
    },
    handleSubmit() {
      if (this.isPartner) {
        this.partnerId = this.partner;
      }
      this.selectedLocations.forEach((selectedLocation) => {
        this.$emit('submit', {
          email: this.user.email,
          userName: this.userName,
          selectedLocation,
          partnerId: this.partnerId,
        });
      });
      this.resetVar();
    },
    closeForm() {
      this.$emit('close');
    },
  },
};
</script>
