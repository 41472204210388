// Your personal API key.
// Get it here: https://console.cloud.google.com/google/maps-apis
import MarkerClusterer from '@googlemaps/markerclustererplus';

const API_KEY = 'AIzaSyD6XVc38mGGv2bEjuwbh5EdejfF5XiTLNU';
const CALLBACK_NAME = 'gmapsCallback';
let initialized = !!window.google;
let resolveInitPromise;
let rejectInitPromise;
// This promise handles the initialization
// status of the google maps script.
const initPromise = new Promise((resolve, reject) => {
  resolveInitPromise = resolve;
  rejectInitPromise = reject;
});
export function gmapInit() {
  // If Google Maps already is initialized
  // the `initPromise` should get resolved
  // eventually.
  if (initialized) {
    if (!window.google) return initPromise;

    return new Promise((resolve) => {
      resolve(window.google);
    });
  }

  initialized = true;
  // The callback function is called by
  // the Google Maps script if it is
  // successfully loaded.
  window[CALLBACK_NAME] = () => resolveInitPromise(window.google);

  // We inject a new script tag into
  // the `<head>` of our HTML to load
  // the Google Maps script.
  const script = document.createElement('script');
  script.async = true;
  script.defer = true;
  script.src = `https://maps.googleapis.com/maps/api/js?key=${API_KEY}&callback=${CALLBACK_NAME}`;
  script.onerror = rejectInitPromise;
  document.querySelector('head').appendChild(script);

  return initPromise;
}

/**
 * @param {HTMLElement} element
 * @param {{lat: Number, lng: Number, zoom: Number}|null} options
 * @returns {Map<any, any> | Map}
 */
export function createMap(element, options) {
  if (!window.google) throw Error('Google Maps API not initialize. Please call "gmapInit" before');

  if (!options) {
    return new window.google.maps.Map(element);
  }

  return new window.google.maps.Map(element, {
    center: options.center,
    zoom: options.zoom,
    mapTypeId: options.maptype,
  });
}

/**
 * Add marker to specified map on specified location.
 * This method accepts additional options:
 *  - draggable -> default false
 * @param {Map} map
 * @param {{lat: Number, lng: Number}} position
 * @param {{draggable: Boolean}} options
 * @param {String|Object} markerIcon
 */
export function addMarker(map, position, options = {}, markerIcon) {
  if (!window.google) throw Error('Google Maps API not initialize. Please call "gmapInit" before');

  const { draggable = false } = options;
  const marker = { position, map, draggable };

  if (markerIcon && markerIcon !== '') {
    let icon = { url: markerIcon };
    if (typeof markerIcon === 'object') {
      icon = markerIcon;
    }
    marker.icon = icon;
  }

  return new window.google.maps.Marker(marker);
}

let markerClusterer = null;
export function addMarkerClusters(map, markers) {
  if (markerClusterer) {
    markerClusterer.clearMarkers();
  }
  markerClusterer = new MarkerClusterer(map, markers, { imagePath: '/marker/cluster/m' });
}

function getBoundsZoomLevel(bounds, mapDim, CoordinatesNum) {
  const WORLD_DIM = { height: 256, width: 256 };
  const ZOOM_MAX = 17;
  let zoomcomputed = 0;

  function latRad(lat) {
    const sin = Math.sin((lat * Math.PI) / 180);
    const radX2 = Math.log((1 + sin) / (1 - sin)) / 2;

    return Math.max(Math.min(radX2, Math.PI), -Math.PI) / 2;
  }

  function zoom(mapPx, worldPx, fraction) {
    return Math.floor(Math.log(mapPx / worldPx / fraction) / Math.LN2);
  }

  const ne = bounds.getNorthEast();
  const sw = bounds.getSouthWest();

  const latFraction = (latRad(ne.lat()) - latRad(sw.lat())) / Math.PI;

  const lngDiff = ne.lng() - sw.lng();
  const lngFraction = ((lngDiff < 0) ? (lngDiff + 360) : lngDiff) / 360;

  const latZoom = zoom(mapDim.height, WORLD_DIM.height, latFraction);
  const lngZoom = zoom(mapDim.width, WORLD_DIM.width, lngFraction);
  if (CoordinatesNum === 1) {
    zoomcomputed = ZOOM_MAX;
  } else {
    zoomcomputed = Math.min(latZoom, lngZoom, ZOOM_MAX);
  }

  return (zoomcomputed);
}

export function fitBounds(map, locations, dim) {
  let CoordinatesNum = 0;
  if (!window.google) throw Error('Google Maps API not initialize. Please call "gmapInit" before');

  const bounds = new window.google.maps.LatLngBounds();
  locations.forEach((coordinates) => {
    CoordinatesNum += 1;
    bounds.extend({ lat: coordinates.lat, lng: coordinates.lng });
  });

  // If only one markers exists there to close zoom so we want to extend bound with 2 more points
  if (bounds.getNorthEast().equals(bounds.getSouthWest())) {
    const extendPoint = {
      lat: bounds.getNorthEast().lat() + 0.001,
      lng: bounds.getNorthEast().lng() + 0.001,
    };
    const extendPoint2 = {
      lat: bounds.getNorthEast().lat() - 0.001,
      lng: bounds.getNorthEast().lng() - 0.001,
    };
    bounds.extend(extendPoint);
    bounds.extend(extendPoint2);
  }
  map.fitBounds(bounds);

  window.google.maps.event.addListenerOnce(map, 'idle', () => {
    map.setZoom(getBoundsZoomLevel(bounds, dim, CoordinatesNum));
  });
}
