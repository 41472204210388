<template>
  <div>
    <v-card>
      <v-container>
        <v-form
          ref="form"
          v-model="valid"
          novalidate="true"
          @submit.prevent="handleSubmit"
        >
          <v-card-title>
            <v-row>
              <v-col cols="11">
                <span class="headline">{{ headline.capitalize() }}</span>
              </v-col>
              <v-col cols="1" class="text-right">
                <v-btn icon @click.stop="closeForm">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-card-title>
          <v-card-text>
            <v-text-field
              v-model="email"
              :disabled="disabledEmailInput"
              :label="$t('form.label.email')"
              name="Email"
              type="email"
              :rules="[rules.required, rules.email]"
            />
            <v-text-field
              v-model="phone"
              :label="$t('form.label.phone')"
              name="Phone"
              :rules="[rules.phoneNumber]"
            />
            <v-text-field
              id="name"
              v-model="name"
              :label="$t('form.label.name')"
              name="name"
              :rules="[rules.required]"
            />
            <v-select
              v-show="!isUserSuperadmin"
              v-model="roles"
              :items="functs"
              item-text="fun_name"
              item-value="fun_id"
              :label="$t('form.label.roles')"
              hide-no-data
              chips
              :rules="[rules.required]"
              @change="onFunctionChange"
            />
            <v-autocomplete
              v-show="!isUserSuperadmin"
              v-model="allowedClients"
              :items="clients"
              item-text="name"
              item-value="id"
              :label="$t('form.label.allowedClients')"
              multiple
              hide-no-data
              auto-select-first
              chips
              :search-input.sync="searchInput"
              :rules="[rules.requiredArray]"
              @input="searchInput=null"
              @change="allowedLocations = []"
            />
            <v-autocomplete
              v-model="client"
              :items="clients"
              item-text="name"
              item-value="id"
              :label="$t('form.label.client')"
              chips
              auto-select-first
              :rules="[rules.required]"
            />
            <v-autocomplete
              v-if="isSuperAdmin && roles !== 6"
              v-model="userPartners"
              :items="partners"
              item-text="name"
              item-value="partner_id"
              :label="$t('form.label.partners')"
              multiple
              hide-no-data
              auto-select-first
              chips
              :disabled="!roles"
              :search-input.sync="searchInputPartners"
              @input="searchInputPartners=null"
            />
            <v-autocomplete
              v-else-if="isSuperAdmin && roles === 6"
              v-model="partnerOfPartner"
              :items="partners"
              item-text="name"
              item-value="partner_id"
              :label="$t('form.label.partner')"
              hide-no-data
              auto-select-first
              chips
              :rules="partnerRule"
              :search-input.sync="searchInputPartners"
              @input="searchInputPartners=null"
            />
            <div v-if="canHaveAllowedLocations">
              <v-autocomplete
                v-show="enableAllowedLocations"
                v-model="allowedLocations"
                :items="locations"
                multiple
                item-text="loc_name"
                item-value="loc_id"
                :label="$t('form.label.locations')"
                chips
                hide-no-data
                :search-input.sync="searchInputLocations"
                auto-select-first
                :rules="[rules.requiredArray]"
                @input="searchInputLocations=null"
              />
            </div>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn
              color="success"
              type="submit"
              :disabled="!valid"
              block
            >
              {{ submitLabel }}
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-container>
    </v-card>
  </div>
</template>

<script>

import {
  required, email, phoneNumber, requiredArray,
} from '@/validation/rules';
import UserObject from '@/model/UsersObject';

export default {
  name: 'ManagementUserForm',
  inject: ['authGuard'],
  props: {
    user: {
      type: Object,
      default: null,
    },
    clients: {
      type: Array,
      default: null,
    },
    functs: {
      type: Array,
      default: null,
    },
    partners: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      valid: true,
      email: '',
      phone: '',
      name: '',
      roles: null,
      allowedClients: [],
      allowedLocations: [],
      clientsToDelete: [],
      clientsToAdd: [],
      rolesToAdd: [],
      oldClients: [],
      locationsToAdd: [],
      locationsToDelete: [],
      oldLocations: [],
      partnersToAdd: [],
      partnersToDelete: [],
      oldPartners: [],
      client: '',
      disabledEmailInput: false,
      searchInput: null,
      searchInputPartners: null,
      searchInputLocations: null,
      rules: {
        required,
        email,
        phoneNumber,
        requiredArray,
      },
      userPartners: [],
      partnerOfPartner: null,
    };
  },
  computed: {
    headline() {
      if (this.user) {
        return this.$t('entity.update', { entity: this.$tc('common.user', 1) });
      }

      return this.$t('entity.add', { entity: this.$tc('common.user', 1) });
    },
    isUserSuperadmin() {
      return this.user && this.user.n3funct.fun_name === 'n3-superadmin';
    },
    submitLabel() {
      if (this.user) {
        return this.$t('action.update');
      }

      return this.$t('action.create');
    },
    isSuperAdmin() {
      return this.authGuard.user.role === 'n3-superadmin';
    },
    isPartner() {
      return this.authGuard.user.role === 'n3-partner';
    },
    canHaveAllowedLocations() {
      return (this.roles === UserObject.n3AdminMidLeader || this.roles === UserObject.n3User);
    },
    enableAllowedLocations() {
      return (this.client && this.allowedClients.length && this.canHaveAllowedLocations);
    },
    locations() {
      const loc = [];
      const filteredClients = this.clients.filter((e) => this.allowedClients.includes(e.id));
      const selectedClient = this.clients.filter((e) => e.id === this.client);
      filteredClients.forEach((client) => {
        client.locations.forEach((location) => {
          loc.push(location);
        });
      });
      selectedClient.forEach((client) => {
        client.locations.forEach((location) => {
          loc.push(location);
        });
      });

      return [...new Set(loc)];
    },
    partnerRule() {
      return this.roles === 6 ? [this.rules.required] : [];
    },
  },
  watch: {
    user(newUser, oldUser) {
      if (newUser == null) {
        this.$refs.form.reset();
        this.enableInput();
      } else if (newUser !== oldUser) {
        this.disableInput();
        this.email = this.user.email;
        this.name = this.user.full_name;
        this.phone = this.user.phone;
        this.client = this.user.n3client?.id;
        this.user.user_has_loc.forEach((location) => {
          this.allowedLocations.push(location.n3location.loc_id);
          this.oldLocations.push(location.n3location.loc_id);
        });
        this.user.user_has_clis.forEach((message) => {
          this.allowedClients.push(message.n3client.id);
          this.oldClients.push(message.n3client.id);
        });
        this.roles = (this.user.n3funct.fun_id);
        // eslint-disable-next-line camelcase
        this.userPartners = this.user.partner_has_users.map((e) => e.n3partner?.partner_id);
        this.oldPartners = this.userPartners;
        // eslint-disable-next-line camelcase
        this.partnerOfPartner = this.user.n3partner?.partner_id;
      }
    },
  },
  mounted() {
    if (this.user) {
      this.email = this.user.email;
      this.name = this.user.full_name;
      this.phone = this.user.phone;
      this.client = this.user.n3client?.id;
      this.oldClients = this.allowedClients;
      this.oldLocations = this.allowedLocations;
      this.user.user_has_clis.forEach((message) => {
        this.allowedClients.push(message.n3client.id);
      });
      this.user.user_has_loc.forEach((location) => {
        this.allowedLocations.push(location.n3location.loc_id);
      });
      this.roles = (this.user.n3funct.fun_id);
      this.user.partner_has_users.forEach((partner) => {
        this.userPartners.push(partner.n3partner);
      });
      // eslint-disable-next-line camelcase
      this.userPartners = this.user.partner_has_users.map((e) => e.n3partner?.partner_id);
      this.oldPartners = this.userPartners;
      // eslint-disable-next-line camelcase
      this.partnerOfPartner = this.user.n3partner?.partner_id;
      this.disableInput();
    } else {
      this.enableInput();
    }
  },
  methods: {
    disableInput() {
      this.disabledEmailInput = true;
    },
    enableInput() {
      this.disabledEmailInput = false;
    },
    resetVar() {
      this.oldClients = [];
      this.clientsToDelete = [];
      this.clientsToAdd = [];
      this.oldLocations = [];
      this.locationsToDelete = [];
      this.locationsToAdd = [];
      this.rolesToDelete = [];
      this.roles = null;
      this.userPartners = [];
      this.partnerOfPartner = null;
      this.oldPartners = [];
      this.partnersToAdd = [];
      this.partnersToDelete = [];
      this.$refs.form.reset();
    },
    loadClientsToDelete() {
      this.oldClients.forEach((oldClient) => {
        if (!this.allowedClients.includes(oldClient)) {
          this.clientsToDelete.push(oldClient);
        }
      });
    },
    loadClientsToAdd() {
      this.allowedClients.forEach((client) => {
        if (!this.oldClients.includes(client)) {
          this.clientsToAdd.push(client);
        }
      });
    },
    loadLocationsToDelete() {
      this.oldLocations.forEach((oldLocation) => {
        if (!this.allowedLocations.includes(oldLocation)) {
          this.locationsToDelete.push(oldLocation);
        }
      });
    },
    loadLocationsToAdd() {
      this.allowedLocations.forEach((location) => {
        if (!this.oldLocations.includes(location)) {
          this.locationsToAdd.push(location);
        }
      });
    },
    loadPartnersToAdd() {
      this.partnersToAdd = this.userPartners.filter((e) => !this.oldPartners.includes(e));
    },
    loadPartnersToDelete() {
      this.partnersToDelete = this.oldPartners.filter((e) => !this.userPartners.includes(e));
    },
    handleSubmit() {
      if (this.isPartner) {
        this.userPartners = [...this.partners.map((e) => e.partner_id)];
      }
      this.loadClientsToDelete();
      this.loadClientsToAdd();
      this.loadLocationsToAdd();
      this.loadLocationsToDelete();
      this.loadPartnersToAdd();
      this.loadPartnersToDelete();
      this.$emit('submit', {
        email: this.email,
        phone: this.phone,
        name: this.name,
        funId: this.roles,
        clientsId: this.allowedClients,
        clientId: this.client,
        clientsToDeleteId: this.clientsToDelete,
        clientsToAddId: this.clientsToAdd,
        allowedLocations: this.allowedLocations,
        locationsToAdd: this.locationsToAdd,
        locationsToDelete: this.locationsToDelete,
        partnersToAdd: this.partnersToAdd,
        partnersToDelete: this.partnersToDelete,
        partner: this.partnerOfPartner,
      });
      this.resetVar();
    },
    closeForm() {
      this.$emit('close');
    },
    onFunctionChange() {
      this.allowedLocations = [];
      this.userPartners = [];
      this.partnerOfPartner = null;
    },
  },
};
</script>
