export default function checkIfPointsAreToClose(firstPoint, secondPoint, radiusInMeters) {
  if (!firstPoint.lat || !firstPoint.long || typeof (firstPoint.lat) !== 'number' || typeof (firstPoint.long) !== 'number'
  || !secondPoint.lat || !secondPoint.long || typeof (secondPoint.lat) !== 'number' || typeof (secondPoint.long) !== 'number'
  || typeof (radiusInMeters) !== 'number') {
    return false;
  }
  const earthRadius = 6371e3;
  const latFirstInRadians = firstPoint.lat * (Math.PI / 180);
  const latSecondInRadians = secondPoint.lat * (Math.PI / 180);
  const latDeltaInRadians = ((firstPoint.lat > secondPoint.lat) ? firstPoint.lat - secondPoint.lat
    : secondPoint.lat - firstPoint.lat) * (Math.PI / 180);
  const longDeltaInRadians = ((firstPoint.long > secondPoint.long) ? firstPoint.long - secondPoint.long
    : secondPoint.long - firstPoint.long) * (Math.PI / 180);
  const haversine = (Math.sin(latDeltaInRadians / 2) * Math.sin(latDeltaInRadians / 2))
          + (Math.cos(latFirstInRadians) * Math.cos(latSecondInRadians)
          * Math.sin(longDeltaInRadians / 2) * Math.sin(longDeltaInRadians / 2));
  const angularDistance = 2 * Math.atan2(Math.sqrt(haversine), Math.sqrt(1 - haversine));
  const distance = earthRadius * angularDistance;

  return !(radiusInMeters < distance);
}
