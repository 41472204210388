<template>
  <v-card>
    <v-container>
      <v-form
        ref="form"
        v-model="valid"
        novalidate="true"
        @submit.prevent="handleSubmit"
      >
        <v-card-title>
          <v-row>
            <v-col cols="11">
              <span class="headline">{{ headline.capitalize() }}</span>
            </v-col>
            <v-col cols="1" class="text-right">
              <v-btn icon @click.stop="closeForm">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-text-field
            v-model="name"
            :label="$t('form.label.name')"
            :rules="nameRules"
            block
            required
          />
          <v-text-field
            v-model="comment"
            :label="$t('form.label.comment')"
            :rules="commentRules"
            block
            required
          />
          <v-autocomplete
            v-if="!isPartner"
            v-model="partnerId"
            :items="partners"
            item-text="name"
            item-value="partner_id"
            :label="$t('form.label.partners')"
            chips
            auto-select-first
            multiple
            :disabled="isPartner"
          />
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="success"
            type="submit"
            :disabled="!valid"
            block
          >
            {{ submitLabel }}
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-container>
  </v-card>
</template>

<script>
import { required as requiredRule } from '@/validation/rules';

export default {
  name: 'ManagementClientForm',
  inject: ['authGuard'],
  props: {
    client: {
      type: Object,
      default: null,
    },
    partners: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      valid: true,
      name: '',
      comment: '',
      nameRules: [
        requiredRule,
      ],
      commentRules: [],
      partnersID: [],
      oldPartners: [],
    };
  },
  computed: {
    isPartner() {
      return this.authGuard.user.role === 'n3-partner';
    },
    headline() {
      if (this.client) {
        return this.$t('entity.update', { entity: this.$tc('common.client', 1) });
      }

      return this.$t('entity.add', { entity: this.$tc('common.client', 1) });
    },
    submitLabel() {
      if (this.client) {
        return this.$t('action.update');
      }

      return this.$t('action.create');
    },
    partnerId: {
      get() {
        if (this.client?.partner_has_clis && this.isPartner) { // eslint-disable-line
          return this.client.partner_has_clis.map((e) => e.n3partner.partner_id);
        }

        return this.partnersID;
      },
      set(partnerId) {
        this.partnersID = partnerId;
      },
    },
  },
  watch: {
    client(newClient, oldClient) {
      if (newClient == null) {
        this.$refs.form.reset();
        this.oldPartners = [];
        this.partnersID = (!this.isPartner) ? [] : this.partners.map((e) => e.partner_id);
      } else if (newClient !== oldClient) {
        this.name = this.client.name;
        this.comment = this.client.comment;
        this.oldPartners = this.client.partner_has_clis.map((e) => e.n3partner.partner_id);
        this.partnersID = (!this.isPartner) ? this.client.partner_has_clis.map((e) => e.n3partner.partner_id)
          : this.partners.map((e) => e.partner_id);
      }
    },
  },
  mounted() {
    if (this.client) {
      this.name = this.client.name;
      this.comment = this.client.comment;
      this.oldPartners = this.client.partner_has_clis.map((e) => e.n3partner.partner_id);
      this.partnersID = this.client.partner_has_clis.map((e) => e.n3partner.partner_id);
    }
    if (this.isPartner) {
      this.partnersID = this.partners.map((e) => e.partner_id);
    }
  },
  methods: {
    handleSubmit() {
      const partnersToAdd = this.partnerId.filter((e) => !this.oldPartners.includes(e));
      const partnersToDelete = this.oldPartners.filter((e) => !this.partnerId.includes(e));
      this.$emit('submit', {
        name: this.name, comment: this.comment, partnersToAdd, partnersToDelete,
      });
      this.$refs.form.reset();
    },
    closeForm() {
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss" scoped></style>
