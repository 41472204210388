<template>
  <v-dialog v-model="visible" max-width="450px">
    <v-card>
      <v-card-title>
        <slot />
      </v-card-title>
      <v-card-actions>
        <v-spacer />
        <v-btn text @click="cancel">
          {{ $t('confirmation.delete.cancel') }}
        </v-btn>
        <v-btn
          ref="btnFocus"
          :color="type"
          @click="confirm"
        >
          {{ $t('confirmation.delete.confirm') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'DialogConfirm',
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    danger: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      visible: false,
    };
  },
  computed: {
    type() {
      if (this.danger) {
        return 'danger';
      }

      return 'primary';
    },
  },
  watch: {
    show(newVal) {
      this.visible = newVal;
    },
    visible(newVal, oldVal) {
      if (newVal === true) {
        setTimeout(() => this.$refs.btnFocus.$el.focus());
      }
      if (oldVal === true && newVal === false) {
        this.cancel();
      }
    },
  },
  methods: {
    cancel() {
      this.visible = false;
      this.$emit('cancel');
    },
    confirm() {
      this.$emit('confirm');
    },
  },
};
</script>

<style lang="scss" scoped>
  .v-btn:not(.v-btn--outlined).danger {
    color: #ffffff;
  }
</style>
