<template>
  <Form
    ref="zoneForm"
    :zone="zoneToEdit"
    :locations="locationsToDisplay"
    :disabled="disabledInputs"
    :categories="categoriesToDisplay"
    :devices="devicesToDisplay"
    :add-from-another-form="addWithRestriction"
    @submit="submit"
    @close="closeDialog"
  />
</template>

<script>

import {
  addZone, addZoneScenario, updateZone, addRobotZone, updateRobotZone,
  addEnvironmentZone, updateEnvironmentZone, addRobotScenario, fetchDevicesNotAssigned,
} from '@/data';
import Form from '@/views/Management/Zones/Form';
import { EventBus } from '@/eventBus';
import { CategoriesNames } from '@/model/CategoriesObjects';

export default {
  name: 'ManagementZoneList',
  components: {
    Form,
  },
  inject: ['authGuard'],
  props: {
    zone: {
      type: Object,
      default: null,
    },
    locations: {
      type: Array,
      default: null,
    },
    device: {
      type: Object,
      default: null,
    },
    disabled: {
      type: Array,
      default: () => [],
    },
    addFromAnotherForm: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      devices: [],
      categories: Object.values(CategoriesNames),
    };
  },
  computed: {
    categoriesToDisplay() {
      const index = this.categories.indexOf('N3Gateway');
      const categoriesModified = Array.from(this.categories);
      categoriesModified.splice(index, 1);

      return categoriesModified;
    },
    addWithRestriction() {
      return this.addFromAnotherForm;
    },
    devicesToDisplay() {
      let devicesToDisplay = [];
      devicesToDisplay = this.devices.slice();
      if (this.zoneToEdit?.device) {
        devicesToDisplay.push(this.zoneToEdit.device);
      }

      return devicesToDisplay;
    },
    disabledInputs() {
      return this.disabled;
    },
    locationsToDisplay() {
      return this.locations;
    },
    zoneToEdit() {
      return this.zone;
    },
  },
  watch: {
    device(newVal) {
      if (newVal !== null) {
        this.devices.push({
          id: newVal.device_id,
          name: newVal.name,
          location: newVal.n3location,
          subcategory: newVal.sub_category_name,
          partnerId: newVal.partnerId,
        });
      }
    },
  },
  created() {
    this.loadDevices();
  },
  methods: {
    async loadDevices() {
      try {
        this.devices = await fetchDevicesNotAssigned();
      } catch (e) {
        EventBus.$emit('generalErrorOccurred', {
          message: 'Cannot load data. Try again in a few seconds',
        });
        console.error(e); // eslint-disable-line
      }
    },
    closeDialog() {
      this.$emit('close');
    },
    async submit(submitted) {
      const { category } = submitted;
      if (this.zoneToEdit && !this.addFromAnotherForm) {
        try {
          let updatedZone;
          if (category === CategoriesNames.N3Zone) {
            updatedZone = await updateZone(this.zoneToEdit.id, submitted);
          } else if (category === CategoriesNames.N3Robot) {
            updatedZone = await updateRobotZone(this.zoneToEdit.id, submitted);
          } else {
            updatedZone = await updateEnvironmentZone(this.zoneToEdit.id, submitted);
          }
          this.$emit('update', { updatedZone, category });
          this.devices = await fetchDevicesNotAssigned();
          this.closeDialog();
          this.$refs.zoneForm.resetValues();
        } catch (error) {
          EventBus.$emit('generalErrorOccurred');
            console.error(error); // eslint-disable-line
          this.closeDialog();
          this.$refs.zoneForm.resetValues();
        }
      } else {
        try {
          const { excludedDays } = submitted;
          let createdZone;
          if (category === CategoriesNames.N3Zone) {
            createdZone = await addZone(submitted);
            createdZone = await addZoneScenario(createdZone.zone_id, excludedDays);
          } else if (category === CategoriesNames.N3Robot) {
            createdZone = await addRobotZone(submitted);
            createdZone = await addRobotScenario(createdZone.zone_id, excludedDays);
          } else {
            createdZone = await addEnvironmentZone(submitted);
          }
          this.$emit('create', { createdZone, category });
          this.devices = await fetchDevicesNotAssigned();
          this.closeDialog();
          this.$refs.zoneForm.resetValues();
        } catch (error) {
          EventBus.$emit('generalErrorOccurred');
            console.error(error); // eslint-disable-line
          this.closeDialog();
        }
      }
    },
  },
};

</script>
<style lang="scss" scoped>
</style>
