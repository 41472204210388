<template>
  <LocationForm
    ref="locationForm"
    :location="locationToEdit"
    :clients="clients"
    :disabled="disabledInputs"
    :coordinates="allCoordinates"
    :partner="partnerOfPartner"
    @submit="submit"
    @close="closeDialog"
  />
</template>

<script>

import {
  addLocation, addLocationStatus, fetchClients, updateLocation, fetchUserIdByEmail,
} from '@/data';
import LocationForm from '@/views/Management/Locations/Form';
import { EventBus } from '@/eventBus';

export default {
  name: 'ManagementLocationList',
  components: {
    LocationForm,
  },
  inject: ['authGuard'],
  props: {
    searchName: {
      type: String,
      default: null,
    },
    location: {
      type: Object,
      default: null,
    },
    names: {
      type: Array,
      default: null,
    },
    coordinates: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      clients: [],
      allCoordinates: this.coordinates,
      partnerOfPartner: [],
    };
  },
  computed: {
    locationToEdit() {
      return this.location;
    },
    disabledInputs() {
      if (this.authGuard.isGranted('n3-admin') || this.authGuard.isGranted('n3-superadmin') || this.authGuard.isGranted('n3-admin-mid-leader') || this.authGuard.isGranted('n3-partner')) {
        return [];
      }

      return ['client', 'name'];
    },
  },
  created() {
    this.loadClients();
  },
  methods: {
    async loadClients() {
      try {
        this.clients = await fetchClients();
        if (this.authGuard.user.role === 'n3-partner' && this.clients.length) {
          this.partnerOfPartner = [this.clients[0].partner_has_clis.map((e) => e.n3partner)[0].partner_id];
        }
      } catch (e) {
        EventBus.$emit('generalErrorOccurred', {
          message: 'Cannot load data. Try again in a few seconds',
        });
        console.error(e) // eslint-disable-line
      }
    },
    submit(submitted) {
      const {
        name, address, clientId, gpsLatitude, gpsLongitude, partnersToAdd, partnersToDelete,
      } = submitted;
      if (this.names.includes(name.toLowerCase().trim())) {
        EventBus.$emit('generalErrorOccurred', {
          message: 'This location name already exists',
        });
      } else if (this.locationToEdit) {
        updateLocation(this.locationToEdit.id, {
          loc_name: name,
          address,
          client_id: clientId,
          gps_latitude: gpsLatitude,
          gps_longitude: gpsLongitude,
        },
        this.locationToEdit,
        partnersToAdd,
        partnersToDelete)
          .then((updatedLocation) => {
            this.$emit('updateLocation', updatedLocation);
          })
          .catch((error) => {
            EventBus.$emit('generalErrorOccurred');
            console.error(error); // eslint-disable-line
          })
          .finally(() => { this.$refs.locationForm.resetComponent(); this.closeDialog(); });
      } else {
        fetchUserIdByEmail(this.authGuard.user.email)
          .then((user) => {
            addLocation(name, address, clientId, gpsLatitude, gpsLongitude, partnersToAdd, user)
              .then((createdLocation) => {
                addLocationStatus(createdLocation.loc_id);
                this.$emit('createLocation', createdLocation);
              })
              .catch((error) => {
                EventBus.$emit('generalErrorOccurred');
                console.error(error); // eslint-disable-line
              })
              .finally(() => { this.$refs.locationForm.resetComponent(); this.closeDialog(); });
          });
      }
    },
    closeDialog() {
      this.$emit('close');
    },
  },
};

</script>
<style lang="scss" scoped>
</style>
