/* eslint-disable */
import { apolloClient } from '@/integration/apollo';
import {
  Zones,
  Users,
  Devices,
  Clients,
  Categories,
  Functs,
  Notifications,
  LocationById,
  DevicesNotAssigned,
  LocationForZonesAndDevices,
  SimpleLocations,
  LocationZonesZones,
  LocationRobotZones,
  LocationEnvironmentZones,
  LocationEnvironmentZonesData,
  LocationDevices,
  Partners,
  LocationPartners,
  UserIdByEmail
} from '@/graphql/query.graphql';
import {
  AddClient, AddUser, AddZone, AddRobotZone, AddEnvironmentZone, AddZoneScenario, AddNotification,
  AddLocation, AddDevice, UpdateClient, UpdateUser, UpdateZone, UpdateLocation, UpdateDevice,
  UpdateNotification, DeleteClient, DeleteUser, DeleteZone, DeleteLocation, DeleteDevice,
  DeleteNotification, DeleteNotificationWithEmail, DeleteZoneScenario,
  DeleteEnvironmentZone, DeleteRobotZone, UpdateRobotZone, UpdateEnvironmentZone, AddLocationStatus,AddRobotScenario, DeleteRobotScenario,RelocateDeviceZone,
  AddPartner, DeletePartner, UpdatePartner
} from '@/graphql/mutation.graphql';
import { format } from 'date-fns';
import { GET_LOCATIONS_FOR_DEVICES } from '@/graphql/location.graphql';

/**
 * @todo handle with API errors
 * @param {String} name
 * @param {String} comment
 * @returns {Promise<null|*>}
 */
export async function addClient(name, comment, partners) {

  const partnersid = [];

  partners.forEach((index) => {
    partnersid.push({ partner_id: index });
  });


  const response = await apolloClient.mutate({
    mutation: AddClient,
    variables: {
      name,
      comment,
      partnersid
    },
  });

  if (response.data.create.returning.length) {
    const storedClients = apolloClient.readQuery({query: Clients});

    const newClient = response.data.create.returning[0];

    apolloClient.writeQuery({
      query: Clients,
      data: {
        clients: [...storedClients.clients, newClient],
      },
    });

    return newClient;
  }

  throw new Error('mutation failed');
}

export async function addPartner(name){
  const response = await apolloClient.mutate({
    mutation: AddPartner,
    variables: {
      name,
    },
  });

  if (response.data.create.returning.length) {
    const storedPartners = apolloClient.readQuery({query: Partners});
    const newPartner = response.data.create.returning[0];
    apolloClient.writeQuery({
      query: Partners,
      data: {
        partners: [...storedPartners.partners, newPartner],
      },
    });

    return newPartner;
  }

  throw new Error('mutation failed');
}

export async function addUser(email, name, clientId, funId, clientsId, phone, allowedLocations, partnersToAdd, partner) {
  const clientsid = [];
  const locationsid = [];
  const partnersid = [];

  partnersToAdd.forEach((index) => {
    partnersid.push({ partner_id: index });
  });
  clientsId.forEach((index) => {
    clientsid.push({ client_id: index });
  });
  allowedLocations.forEach((index) => {
    locationsid.push({ location_id: index });
  });


  try {
    const response = await apolloClient.mutate({
      mutation: AddUser,
      variables: {
        email,
        name,
        clientId,
        funId,
        clientsid,
        phone,
        locationsid,
        partnersid,
        partner
      },
    });


    if (!response.data.create.returning.length) {
      console.error('API response: ', response); // eslint-disable-line no-console
      // @todo better error handling
      throw new Error('Could not create an user');
    }
    const storedUsers = apolloClient.readQuery({query: Users});
    const newUser = response.data.create.returning[0];

    apolloClient.writeQuery({
      query: Users,
      data: {
        users: [...storedUsers.users, newUser],
      },
    });

    return newUser;
  } catch (error) {
    throw new Error(error);
  }
}

/**
 *
 * @param {
 *  name: string
 *  sensor: int
 *  gpsLatitude: double
 *  gpsLongitude: double
 *  locationId: int
 *  zoneSize: double,
 *  expectedCleaningTime: int
 *  sendNotification: boolen
 *  roomId: string
 *  serverUrl: string
 *  sendCleanPilot: boolen
 * } param0
 * @returns ZoneZone
 */
export async function addZone({name, sensor, gpsLatitude, gpsLongitude, locationId, zoneSize,
  expectedCleaningTime, sendNotification, roomId, serverUrl, sendCleanPilot, partnerId}) {
  const response = await apolloClient.mutate({
    mutation: AddZone,
    variables: {
      zoneName: name,
      sensor,
      gpsLat: gpsLatitude,
      gpsLon: gpsLongitude,
      locId: locationId,
      roomSize: zoneSize,
      expectedCleaningTime,
      sendNotification,
      roomId,
      serverUrl,
      sendCleanPilot,
      partnerId
    },
  });
  if (response.data.create.returning.length) {
    return response.data.create.returning[0];
  }

  throw new Error('mutation failed');
}

/**
 *
 * @param {
 *  name: string
 *  sensor: int
 *  gpsLatitude: double
 *  gpsLongitude: double
 *  locationId: int
 *  expectedCleaningTime: int
 *  sendNotification: boolen
 *  floorId: string
 * } param0
 * @returns RobotZone
 */
export async function addRobotZone({name, sensor, gpsLatitude, gpsLongitude, locationId, expectedCleaningTime, sendNotification, floorId, partnerId,
  roomId, serverUrl, sendCleanPilot}) {
  const response = await apolloClient.mutate({
    mutation: AddRobotZone,
    variables: {
      zoneName: name,
      sensor,
      gpsLat: gpsLatitude,
      gpsLon: gpsLongitude,
      locId: locationId,
      expectedCleaningTime,
      sendNotification,
      floorId,
      roomId,
      serverUrl,
      sendCleanPilot,
      partnerId,
    },
  });

  if (response.data.create.returning.length) {
    return response.data.create.returning[0];
  }

  throw new Error('mutation failed');
}

/**
 *
 * @param {
 *  name: string
 *  sensor: int
 *  gpsLatitude: double
 *  gpsLongitude: double
 *  locationId: int
 *  sendNotification: boolen
 *  floorId: string
 *  roomId: string
 *  minTemperature: int
 *  maxTemperature: int
 *  minHumidity: int
 *  maxHumidity: int
 *  minCo2: int
 *  maxCo2: int
 *  minDust: int
 *  maxDust: int
 * } param0
 * @returns EnvironmentZone
 */
export async function addEnvironmentZone({name, sensor, gpsLatitude, gpsLongitude, locationId, sendNotification,
  minTemperature, maxTemperature, minHumidity, maxHumidity, minCo2, maxCo2, minDust, maxDust, floorId, roomId, partnerId}) {
  const response = await apolloClient.mutate({
    mutation: AddEnvironmentZone,
    variables: {
      zoneName: name,
      sensor,
      gpsLat: gpsLatitude,
      gpsLon: gpsLongitude,
      locId: locationId,
      sendNotification,
      minTemperature,
      maxTemperature,
      minHumidity,
      maxHumidity,
      minCo2,
      maxCo2,
      minDust,
      maxDust,
      floorId,
      roomId,
      partnerId,
    },
  });

  if (response.data.create.returning.length) {
    return response.data.create.returning[0];
  }

  throw new Error('mutation failed');
}

export async function addZoneScenario(zone_id, excludedDays) {
  const scenarioList = [];
  excludedDays.forEach((day) => {
    scenarioList.push({ zone_id, ddow_id: day });
  });
  const response = await apolloClient.mutate({
    mutation: AddZoneScenario,
    variables: {
      scenarioList,
    },
  });

  if (response.data.create.returning.length) {
    return response.data.create.returning[0].n3zone;
  }

  throw new Error('mutation failed');
}

export async function addRobotScenario(zone_id, excludedDays) {
  const scenarioList = [];
  excludedDays.forEach((day) => {
    scenarioList.push({ zone_id, ddow_id: day });
  });
  const response = await apolloClient.mutate({
    mutation: AddRobotScenario,
    variables: {
      scenarioList,
    },
  });
  if (response.data.create.returning.length) {
    return response.data.create.returning[0].n3robot;
  }

  throw new Error('mutation failed');
}

export async function addLocation(locName, address, clientId, gpsLat, gpsLon, partnersIds, user) {
  const partners = []
  partnersIds.forEach((id) => {
    partners.push({partner_id: id})
  })
  const response = await apolloClient.mutate({
    mutation: AddLocation,
    variables: {
      locName,
      address,
      clientId,
      gpsLat,
      gpsLon,
      partners,
      user,
    },
  });

  if (response.data.create.returning.length) {
    const storedLocations = apolloClient.readQuery({query: LocationForZonesAndDevices});

    const { loc_id, loc_name, partner_has_locs } = response.data.create.returning[0];
    const newLocation = { loc_id, loc_name, partner_has_locs, __typename: 'n3location' }
    apolloClient.writeQuery({
      query: LocationForZonesAndDevices,
      data: {
        locations: [...storedLocations.locations, newLocation],
      },
    });
    return response.data.create.returning[0];
  }

  throw new Error('mutation failed');
}

export async function addLocationStatus(id) {
  const response = await apolloClient.mutate({

    mutation: AddLocationStatus,
    variables: {
      id
    },
  });
  if (response.data.create.returning.length) {
    return response.data.create.returning[0];
  }
  return null;
}

export async function addDevice(sensoridtype, sensoridvalue, categoryname, subcategoryName, deviceName, locId, gpsLat, gpsLong, externalEquipment, partnerId) {
  const response = await apolloClient.mutate({
    mutation: AddDevice,
    variables: {
      sensoridtype,
      sensoridvalue,
      subcategoryName,
      deviceName,
      locId,
      gpsLat,
      gpsLong,
      externalEquipment,
      partnerId,
    },
    refetchQueries: [
      { query: GET_LOCATIONS_FOR_DEVICES,
      variables: {subcategoryNames: [subcategoryName] }
      },
    ],
  });

  if (!response.data.create) {
    console.error('API response: ', response); // eslint-disable-line no-console
    // @todo better error handling
    throw new Error('Could not create an device');
  }

  return response.data.create;
}

export async function addNotification(email, name, locid, partnerId) {
  const response = await apolloClient.mutate({
    mutation: AddNotification,
    variables: {
      email,
      name,
      locid,
      partnerId,
    },
  });

 if (response.data.create.returning.length) {
  const storedNotifications = apolloClient.readQuery({query: Notifications});
  const newNotification = response.data.create.returning[0];

  apolloClient.writeQuery({
    query: Notifications,
    data: {
      notifications: [...storedNotifications.notifications, newNotification],
    },
  });

    return newNotification;
  }

  throw new Error('mutation failed');

}
/**
 * @todo handle with API errors
 * @param {Number} id
 * @param {Object} data
 * @returns {Promise<void>}
 */
export async function updateClient(id, data, partnersToAdd, partnersToDelete) {
  const partnersAdd = []
  partnersToAdd.forEach((e) => {
    partnersAdd.push({partner_id: e, client_id: id});
  })
  const response = await apolloClient.mutate({
    mutation: UpdateClient,
    variables: {
      id,
      data,
      partnersAdd,
      partnersToDelete,
    },
  });
  if (response.data.update.returning.length) {
    const storedClients = apolloClient.readQuery({query: Clients});
    const newClient = response.data.update.returning[0];
    const index = storedClients.clients.findIndex((e) => e.id === id);
    storedClients.clients.splice(index, 1, newClient);
    apolloClient.writeQuery({
      query: Clients,
      data: {
        clients: [...storedClients.clients]
      }
    });
    return newClient;
  }

  return null;
}

export async function updatePartner(id, data, partnerToEdit){
  const response = await apolloClient.mutate({
    mutation: UpdatePartner,
    variables: {
      id,
      data,
    },
    update: (apolloClient) => {
      const storedPartners = apolloClient.readQuery({query: Partners});
      const index = storedPartners.partners.indexOf(partnerToEdit)
      const editedPartner = { partner_id: id, name: data.name, __typename: 'n3partner' };
      storedPartners.partners.splice(index, 1, editedPartner)
      apolloClient.writeQuery({
        query: Partners,
        data: {
          partners: [...storedPartners.partners]
        }
      });
  }
  });
  if (response.data.update.returning.length) {
    return response.data.update.returning[0];
  }

  return null;
}

export async function updateUser(id, data, clisToAdd, clisToDelete, locationsToAdd, locationsToDelete, partnersToAdd, partnersToDelete) {
  const clientstoaddid = [];
  const locationstoaddid = [];
  const partnersid = [];
  if (clisToAdd.length) {
    clisToAdd.forEach((index) => {
      clientstoaddid.push({ client_id: index, user_id: id });
    });
  }
  if (locationsToAdd.length) {
    locationsToAdd.forEach((index) => {
      locationstoaddid.push({ location_id: index, user_id: id });
    });
  }
  if (partnersToAdd.length){
    partnersToAdd.forEach((index) => {
      partnersid.push({ user_id: id, partner_id: index });
    });
  }

  const response = await apolloClient.mutate({
    mutation: UpdateUser,
    variables: {
      id,
      data,
      clientstoaddid,
      clisToDelete,
      locationstoaddid,
      locationsToDelete,
      partnersid,
      partnersToDelete
    },
  });
  if (response.data.update.returning.length) {
    const storedPartners = apolloClient.readQuery({query: Users});
    const index = storedPartners.users.findIndex((e) => e.user_id === id)
    const editedUser = response.data.update.returning[0];

    storedPartners.users.splice(index, 1, editedUser)
    apolloClient.writeQuery({
      query: Users,
      data: {
        users: [...storedPartners.users]
      }
    });
    return editedUser;
  }

  return null;
}

export async function deleteZoneScenario(zoneid) {
  const response = await apolloClient.mutate({
    mutation: DeleteZoneScenario,
    variables: {
      zoneid,
    },
  });

  if (response.data.delete.delete_scenario !== 1) {
    // throw new Error('mutation failed')
  }
}
export async function deleteRobotScenario(zoneid) {
  const response = await apolloClient.mutate({
    mutation: DeleteRobotScenario,
    variables: {
      zoneid,
    },
  });

  if (response.data.delete.delete_scenario !== 1) {
    // throw new Error('mutation failed')
  }
}

/**
 * @param {int} id
 * @param {
 *  name: string
 *  sensor: int
 *  gpsLatitude: double
 *  gpsLongitude: double
 *  locationId: int
 *  zoneSize: double,
 *  expectedCleaningTime: int
 *  sendNotification: boolen
 *  roomId: string
 *  serverUrl: string
 *  sendCleanPilot: boolen
 *  excludedDays: Array {int}
 * } param0
 * @returns ZoneZone
 */
export async function updateZone(id, {name, sensor, gpsLatitude, gpsLongitude, locationId, zoneSize, expectedCleaningTime,
sendNotification, roomId, serverUrl, sendCleanPilot, excludedDays, partnerId}) {
  await deleteZoneScenario(id);
  if (excludedDays.length) {
    await addZoneScenario(id, excludedDays);
  }
  const response = await apolloClient.mutate({
    mutation: UpdateZone,
    variables: {
      id,
      zoneName: name,
      sensor,
      gpsLat: gpsLatitude,
      gpsLon: gpsLongitude,
      locId: locationId,
      roomSize: zoneSize,
      expectedCleaningTime,
      sendNotification,
      roomId,
      serverUrl,
      sendCleanPilot,
      partnerId,
    },
  });


  if (response.data.update.returning.length) {
    return response.data.update.returning[0];
  }

  return null;
}

/**
 *
 * @param {int} id
 * @param {
 *  name: string
 *  sensor: int
 *  gpsLatitude: double
 *  gpsLongitude: double
 *  locationId: int
 *  expectedCleaningTime: int
 *  sendNotification: boolen
 *  floorId: string
 *  excludedDays: Array {int}
 * } param0
 * @returns RobotZone
 */
export async function updateRobotZone(id, {name, sensor, gpsLatitude, gpsLongitude, locationId, expectedCleaningTime, sendNotification, floorId, excludedDays, partnerId, roomId, serverUrl, sendCleanPilot}) {
  await deleteRobotScenario(id);
  if(excludedDays.length) {
    await addRobotScenario(id, excludedDays);
  }
  const response = await apolloClient.mutate({
    mutation: UpdateRobotZone,
    variables: {
      id,
      zoneName: name,
      sensor,
      gpsLat: gpsLatitude,
      gpsLon: gpsLongitude,
      locId: locationId,
      expectedCleaningTime,
      sendNotification,
      floorId,
      roomId,
      serverUrl,
      sendCleanPilot,
      partnerId,
    },
  });


  if (response.data.update.returning.length) {
    return response.data.update.returning[0];
  }

  return null;
}

/**
 *
 * @param {int} id
 * @param {
 *  name: string
 *  sensor: int
 *  gpsLatitude: double
 *  gpsLongitude: double
 *  locationId: int
 *  sendNotification: boolen
 *  floorId: string
 *  roomId: string
 *  minTemperature: int
 *  maxTemperature: int
 *  minHumidity: int
 *  maxHumidity: int
 *  minCo2: int
 *  maxCo2: int
 *  minDust: int
 *  maxDust: int
 * } param0
 * @returns EnvironmentZone
 */
export async function updateEnvironmentZone(id, {name, sensor, gpsLatitude, gpsLongitude, locationId, sendNotification,
  minTemperature, maxTemperature, minHumidity, maxHumidity, minCo2, maxCo2, minDust, maxDust, floorId, roomId, partnerId}) {
  const response = await apolloClient.mutate({
    mutation: UpdateEnvironmentZone,
    variables: {
      id,
      zoneName: name,
      sensor,
      gpsLat: gpsLatitude,
      gpsLon: gpsLongitude,
      locId: locationId,
      sendNotification,
      minTemperature,
      maxTemperature,
      minHumidity,
      maxHumidity,
      minCo2,
      maxCo2,
      minDust,
      maxDust,
      floorId,
      roomId,
      partnerId,
    },
    refetchQueries: [
      {
        query: LocationEnvironmentZonesData,
        variables: {
          locations: locationId,
          date_start_d: format(new Date(), 'yyyy-MM-dd'),
          date_stop_d: format(new Date((new Date()).valueOf() + 86400000), 'yyyy-MM-dd')
        }
      },
    ],
  });


  if (response.data.update.returning.length) {
    return response.data.update.returning[0];
  }

  return null;
}

export async function updateLocation(id, { loc_name, address, client_id, gps_latitude, gps_longitude }, locationToEdit, partnersToAdd, partnersToDelete) {
  const partnersAdd = []
  partnersToAdd.forEach((e) => {
    partnersAdd.push({partner_id: e, location_id: id});
  })
  const response = await apolloClient.mutate({
    mutation: UpdateLocation,
    variables: {
      id,
      loc_name,
      address,
      client_id,
      gps_latitude,
      gps_longitude,
      partnersAdd,
      partnersToDelete,
    },
  });

  if (response.data.update.returning.length) {
    const storedLocations = apolloClient.readQuery({query: LocationForZonesAndDevices});
    const index = storedLocations.locations.findIndex((e) => e.loc_id === id);
    const editedLocation = response.data.update.returning[0];
    storedLocations.locations.splice(index, 1, editedLocation);
    apolloClient.writeQuery({
      query: LocationForZonesAndDevices,
      data: {
        locations: [...storedLocations.locations]
      }
    });
    return editedLocation;
  }

  return null;
}

export async function updateDevice(id, { name, sensor_id_type, sensor_id_value, location_id, gps_latitude, gps_longitude, external_equipment, partner_id }, subcategoryName) {
  const response = await apolloClient.mutate({
    mutation: UpdateDevice,
    variables: {
      id,
      name,
      sensor_id_type,
      sensor_id_value,
      location_id,
      gps_latitude,
      gps_longitude,
      external_equipment,
      partner_id,
    },
    refetchQueries: [
      { query: GET_LOCATIONS_FOR_DEVICES,
        variables: {subcategoryNames: [subcategoryName] }
      },
    ],
  });
  if (response.data.update) {
    return response.data.update;
  }

  return null;
}

export async function updateNotification(email, name) {
  await fetchNotifications(); //fetch this location here as well to prevent error while updating cache
  const response = await apolloClient.mutate({
    mutation: UpdateNotification,
    variables: {
      email,
      name,
    },
  });

  if (response.data.update.returning.length) {
    const storedNotifications = apolloClient.readQuery({
      query: Notifications
    });
    let newNotifications = storedNotifications.notifications;
    newNotifications.forEach((notification) => {
      if(notification.email === email){
        notification.name = name;
      }
    });

    apolloClient.writeQuery({
      query: Notifications,
      data: {
        notifications: [...newNotifications]
      }
    });

    return response.data.update.returning[0];
  }

  return null;
}


/**
 * @todo handle with API errors
 * @param {Number} id
 * @returns {Promise<void>}
 */
export async function deleteClient(id, newClients) {
  const response = await apolloClient.mutate({
    mutation: DeleteClient,
    variables: {
      id,
    },
  });

  if (response.data.update.update_n3client !== 1) {
    // throw new Error('mutation failed');
  }
  apolloClient.writeQuery({
    query: Clients,
    data: {
      clients: newClients,
    },
  });
}

export async function deletePartner(id, newPartners) {
  const response = await apolloClient.mutate({
    mutation: DeletePartner,
    variables: {
      id,
    },
  });

  if (response.data.update.update_n3partner !== 1) {
    // throw new Error('mutation failed');
  }
  apolloClient.writeQuery({
    query: Partners,
    data: {
      partners: newPartners,
    },
  });
}

export async function deleteUser(id, newUsers) {
  const response = await apolloClient.mutate({
    mutation: DeleteUser,
    variables: {
      id,
    },
  });

  if (response.data.update.update_n3user !== 1) {
    // throw some error
  }

  apolloClient.writeQuery({
    query: Users,
    data: {
      users: newUsers,
    },
  });
}

export async function deleteZone(id) {
  const response = await apolloClient.mutate({
    mutation: DeleteZone,
    variables: {
      id,
    },
  });

  if (response.data.update.update_n3zone !== 1) {
    // throw new Error('mutation failed');
  }
}

export async function deleteRobotZone(id) {
  const response = await apolloClient.mutate({
    mutation: DeleteRobotZone,
    variables: {
      id,
    },
  });

  if (response.data.update.update_n3robot_zone !== 1) {
    // throw new Error('mutation failed');
  }
}

export async function deleteEnvironmentZone(id) {
  const response = await apolloClient.mutate({
    mutation: DeleteEnvironmentZone,
    variables: {
      id,
    },
  });

  if (response.data.update.update_n3environment_zone !== 1) {
    // throw new Error('mutation failed');
  }
}


export async function deleteLocation(id) {
  const response = await apolloClient.mutate({
    mutation: DeleteLocation,
    variables: {
      id,
    },
    update: (apolloClient) => {
      const storedLocations = apolloClient.readQuery({query: LocationForZonesAndDevices});
      const [location] = storedLocations.locations.filter((e) => e.loc_id === id);
      const index = storedLocations.locations.indexOf(location);
      storedLocations.locations.splice(index, 1);

      apolloClient.writeQuery({
        query: LocationForZonesAndDevices,
        data: {
          locations: [...storedLocations.locations]
        }
      });
    }
  });

  if (response.data.update.update_n3location !== 1) {
    // throw new Error('mutation failed');
  }
}

export async function deleteDevice(id, subcategoryName) {
  return apolloClient.mutate({
    mutation: DeleteDevice,
    variables: {
      id,
    },
    refetchQueries: [
      { query: GET_LOCATIONS_FOR_DEVICES,
        variables: {subcategoryNames: [subcategoryName] }
        },
    ],
  });
}

export async function deleteNotification(id, newNotifications) {

  const response = await apolloClient.mutate({
    mutation: DeleteNotification,
    variables: {
      id,
    },
  });

  apolloClient.writeQuery({
    query: Notifications,
    data: {
      notifications: newNotifications,
    },
  });

  if (response.data.delete_notification !== 1) {
    // throw new Error('mutation failed')
  }
}

export async function deleteNotificationWithEmail(email) {
  const response = await apolloClient.mutate({
    mutation: DeleteNotificationWithEmail,
    variables: {
      email,
    },
  });
  if (response.data.delete_notification !== 1) {
    // throw new Error('mutation failed')
  }
  if (response.data.delete.affected_rows) {
    const storedNotifications = apolloClient.readQuery({
      query: Notifications
    });
    let newNotifications = storedNotifications.notifications;
    newNotifications.forEach((notification) => {
      if(notification.email === email){
        newNotifications = newNotifications.splice(newNotifications.indexOf(notification), 1);
      }
    });

    apolloClient.writeQuery({
      query: Notifications,
      data: [...newNotifications]
    });
  }
}

export async function fetchLocationForZonesAndDevices() {
  const response = await apolloClient.query({
    query: LocationForZonesAndDevices,
  });
  return response.data.locations;
}

export async function fetchZones() {
  const loc = await apolloClient.query({
    query: Zones,
    fetchPolicy: 'no-cache',
  });

  return loc.data;
}

export async function fetchUsers() {
  const loc = await apolloClient.query({
    query: Users,
  });
  return loc.data.users;
}

export async function fetchDevices() {
  const loc = await apolloClient.query({
    query: Devices,
    fetchPolicy: 'no-cache'
  });

  return loc.data.devices;
}

// @todo handle with API errors
export async function fetchClients() {
  const response = await apolloClient.query({
    query: Clients,
  });

  return response.data.clients;
}

export async function fetchCategories() {
  const response = await apolloClient.query({
    query: Categories,
    fetchPolicy: 'no-cache',
  });

  return response.data.categories;
}

export async function fetchFuncts() {
  const response = await apolloClient.query({
    query: Functs,
  });

  return response.data.functs;
}

export async function fetchNotifications() {
  const response = await apolloClient.query({
    query: Notifications,
  });

  return response.data.notifications;
}

export async function fetchLocationById(id) {
  const response = await apolloClient.query({
    query: LocationById,
    variables: { id },
    fetchPolicy: 'no-cache',
  });

  return response.data.location;
}

export async function fetchDevicesNotAssigned() {
  const response = await apolloClient.query({
    query: DevicesNotAssigned,
    fetchPolicy: 'no-cache',
  });

  return response.data.devices;
}

export async function fetchSimpleLocations() {
  fetchLocationForZonesAndDevices(); //fetch this location here as well to prevent error while updating cache
  const response = await apolloClient.query({
    query: SimpleLocations,
    fetchPolicy: 'no-cache',
  });

  return response.data.locations;
}

export async function fetchLocationsZones() {
  const response = await apolloClient.query({
    query: LocationZonesZones,
    fetchPolicy: 'no-cache',
  });

  return response.data.zones;
}

export async function fetchLocationsRobots() {
  const response = await apolloClient.query({
    query: LocationRobotZones,
    fetchPolicy: 'no-cache',
  });

  return response.data.robotszone;
}

export async function fetchLocationsEnv() {
  const response = await apolloClient.query({
    query: LocationEnvironmentZones,
    fetchPolicy: 'no-cache',
  });

  return response.data.environmentszone;
}

export async function fetchLocationsDev() {
  const response = await apolloClient.query({
    query: LocationDevices,
    fetchPolicy: 'no-cache',
  });

  return response.data.devices;
}

export async function relocateDeviceZone(id, location_id, gpsLatitude, gpsLongitude, category, zoneName, partnerId) {
  const isZone = ( category === 'N3Zones' );
  const isRobot = ( category === 'N3Assets' );
  const isEnvironment = ( category === 'N3Environment' );

  const response = await apolloClient.mutate({
    mutation: RelocateDeviceZone,
    variables: {
      id,
      location_id,
      gpsLatitude,
      gpsLongitude,
      isZone,
      isRobot,
      isEnvironment,
      zoneName,
      partnerId
    }
  })
  if (response) {
    return response;
  }

  return null;
}

export async function fetchPartners() {
  const partner = await apolloClient.query({
    query: Partners,
  });

  return partner.data.partners;
}

export async function fetchLocationPartners(id){
  const locationParners = await apolloClient.query({
    query: LocationPartners,
    variables: { id },
  })

  return locationParners.data.locationPartners
}

export async function fetchUserIdByEmail(email) {
  const user = await apolloClient.query({
    query: UserIdByEmail,
    variables: { email },
  })
  const usersIds = user.data.user.map((e) => {return {user_id: e.user_id}});
  return usersIds;
}