<template>
  <v-card class="fill-height">
    <ManagementBar
      :title="$tc('common.notification', 2).capitalize()"
      :listsize="notifications.length"
    >
      <v-text-field
        v-model="search"
        prepend-inner-icon="mdi-magnify"
        :label="`${$t('entity.find', { entity: $tc('common.notification', 1) })}...`.capitalize()"
        single-line
        hide-details
      />
      <template #actions>
        <v-btn
          color="success"
          small
          @click.stop="openDialog"
        >
          <v-icon small>
            mdi-plus
          </v-icon>
          {{ $t('entity.new', { entity: $tc('common.notification', 1) }) }}
        </v-btn>
      </template>
      <v-select
        v-if="isSuperAdmin"
        v-model="selectedPartner"
        :loading="loading"
        :items="partners"
        item-text="name"
        item-value="partner_id"
        class="v-select"
        flat
        solo
        label="Partners"
        clearable
      />
    </ManagementBar>
    <v-data-table
      :headers="headers"
      :items="notificationsToDisplay"
      :search="search"
      :loading="loading"
      :footer-props="{
        itemsPerPageOptions:[20, 50, 100, -1]
      }"
      :disable-pagination="false"
      item-key="id"
      fixed-header
    >
      <template #loading>
        <v-skeleton-loader
          class="mx-auto"
          max-width="80%"
          type="table-row@3"
        />
      </template>

      <template #[`item.created`]="{item}">
        {{ item.created | gmtTime | dateWithTime }}
      </template>

      <template #no-data>
        <v-card-text class="pa-8">
          <v-icon
            slot="icon"
            size="64"
            class="mb-6"
          >
            mdi-city
          </v-icon>
          <p class="display-1 text--primary">
            {{ $t('entity.empty.headline', { entities: $tc('common.notification', 2) }) }}
          </p>
          <p>{{ $t('entity.empty.description', { entities: $tc('common.notification', 2) }) }}</p>
          <v-btn
            tile
            color="blue lighten-4"
            @click.stop="openDialog"
          >
            {{ $t('entity.add', { entity: $tc('common.notification', 1) }) }}
          </v-btn>
        </v-card-text>
      </template>

      <template #[`item.action`]="{ item }">
        <span class="actions">
          <v-icon
            small
            @click.stop="confirmDeleteItem(item)"
          >
            mdi-delete
          </v-icon>
        </span>
      </template>
    </v-data-table>
    <v-dialog v-model="dialog" max-width="480px">
      <NotificationForm
        :users="users"
        :partner="partnerId"
        @submit="submit"
        @close="closeDialog"
      />
    </v-dialog>
    <DialogConfirm
      :show="confirmDialog"
      :danger="true"
      @cancel="closeConfirmDialog"
      @confirm="deleteItem"
    >
      {{ $t('confirmation.delete.title') }}
    </DialogConfirm>
  </v-card>
</template>

<script>
import {
  fetchNotifications, addNotification, deleteNotification, fetchUsers, fetchPartners,
} from '@/data';
import NotificationForm from '@/views/Management/Notifications/Form';
import ManagementBar from '@/components/ManagementBar';
import DialogConfirm from '@/components/Dialog/Confirm';
import { EventBus } from '@/eventBus';

export default {
  name: 'ManagementNotifications',
  components: {
    DialogConfirm,
    ManagementBar,
    NotificationForm,
  },
  inject: ['authGuard'],
  data() {
    return {
      notifications: [],
      users: [],
      search: '',
      loading: false,
      dialog: false,
      confirmDialog: false,
      notificationToRemove: null,
      partners: [],
      selectedPartner: null,
      headers: [
        { text: this.$t('table.notification.header.name'), value: 'name' },
        { text: this.$t('table.notification.header.email'), value: 'email' },
        { text: this.$t('table.notification.header.location'), value: 'n3location.loc_name' },
        { text: this.$t('table.notification.header.created'), value: 'created', width: '170px' },
        { text: this.$t('table.notification.header.partner'), value: 'n3partner.name', width: '170px' },
        {
          text: '',
          value: 'action',
          filterable: false,
          sortable: false,
          width: '40px',
        },
      ],

    };
  },
  computed: {
    isSuperAdmin() {
      return this.authGuard.isGranted('n3-superadmin');
    },
    isPartner() {
      return this.authGuard.user.role === 'n3-partner';
    },
    partnerId() {
      if (this.users.length && this.isPartner) {
        const userPartner = this.users.find((e) => e.n3funct.fun_name === 'n3-partner');

        return userPartner ? userPartner.n3partner.partner_id : null;
      }

      return null;
    },
    notificationsToDisplay() {
      if (!this.notifications) {
        return [];
      }
      if (this.selectedPartner) {
        return this.notifications.filter((e) => e.n3partner?.partner_id === this.selectedPartner); // eslint-disable-line
      }
      
      return this.notifications;
    },
  },
  created() {
    this.loadNotifications();
    this.loadUsersbyClients();
    this.loadPartners();
  },
  methods: {
    async loadNotifications() {
      this.loading = true;
      try {
        this.notifications = await fetchNotifications();
        this.loading = false;
      } catch (e) {
        EventBus.$emit('generalErrorOccurred', {
          message: 'Cannot load data. Try again in a few seconds',
        });
        console.error(e); // eslint-disable-line
      }
    },
    async loadUsersbyClients() {
      this.loading = true;
      try {
        this.users = await fetchUsers();
        this.loading = false;
      } catch (e) {
        EventBus.$emit('generalErrorOccurred', {
          message: 'Cannot load data. Try again in a few seconds',
        });
        console.error(e); // eslint-disable-line
      }
    },
    async loadPartners() {
      try {
        this.partners = await fetchPartners();
      } catch (e) {
        EventBus.$emit('generalErrorOccurred', {
          message: 'Cannot load data. Try again in a few seconds',
        });
        console.error(e); // eslint-disable-line
      }
    },
    openDialog() {
      this.dialog = true;
    },
    closeDialog() {
      this.dialog = false;
    },
    confirmDeleteItem(item) {
      this.notificationToRemove = item;
      this.confirmDialog = true;
    },
    closeConfirmDialog() {
      this.confirmDialog = false;
    },
    submit(submitted) {
      const {
        email, userName, selectedLocation, partnerId,
      } = submitted;
      const existingNotification = this.notifications.filter((e) => e.loc_id === selectedLocation && e.email === email && e.name === userName);
      if (existingNotification.length === 0) {
        addNotification(email, userName, selectedLocation, partnerId)
          .then((createdNotification) => {
            this.notifications.push(createdNotification);
          })
          .catch((error) => {
            EventBus.$emit('generalErrorOccurred');
          console.error(error); // eslint-disable-line
          })
          .finally(this.closeDialog);
      } else {
        this.closeDialog();
        EventBus.$emit('generalErrorOccurred', {
          message: 'This notification already exists!',
        });
      }
    },
    deleteItem() {
      const index = this.notifications.indexOf(this.notificationToRemove);
      if (index < 0) {
        console.error('This should not happen'); // eslint-disable-line

        return;
      }
      this.notifications.splice(index, 1);
      this.closeConfirmDialog();
      deleteNotification(this.notificationToRemove.id, this.notifications)
        .catch((error) => {
          EventBus.$emit('generalErrorOccurred', {
            message: 'Cannot remove notification',
          });
          this.notifications.splice(index, 0, this.notificationToRemove);
          console.error(error); // eslint-disable-line
        });
    },
  },
};
</script>
<style lang="scss" scoped>
  table {
    tr {
      .actions {
        .v-icon {
          visibility: hidden;
        }
      }
      &:hover {
        .actions {
          .v-icon {
            visibility: visible;
          }
        }
      }
    }
  }
  .v-select {
    width: 1%;
    justify-content: center;
    margin-top: 25px;
}
</style>
