<template>
  <v-card>
    <v-container>
      <v-form
        ref="form"
        v-model="valid"
        novalidate="true"
        @submit.prevent="handleSubmit"
      >
        <v-card-title>
          <v-row>
            <v-col cols="11">
              <span class="headline">{{ headline.capitalize() }}</span>
            </v-col>
            <v-col cols="1" class="text-right">
              <v-btn icon @click.stop="closeForm">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-text-field
            v-model="name"
            :label="$t('form.label.name')"
            :rules="nameRules"
            block
            required
          />
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="success"
            type="submit"
            :disabled="!valid"
            block
          >
            {{ submitLabel }}
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-container>
  </v-card>
</template>

<script>
import { required as requiredRule } from '@/validation/rules';

export default {
  name: 'ManagementClientForm',
  props: {
    partner: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      name: '',
      valid: true,
      nameRules: [
        requiredRule,
      ],
    };
  },
  computed: {
    headline() {
      if (this.partner) {
        return this.$t('entity.update', { entity: this.$tc('common.partner', 1) });
      }

      return this.$t('entity.add', { entity: this.$tc('common.partner', 1) });
    },
    submitLabel() {
      if (this.partner) {
        return this.$t('action.update');
      }

      return this.$t('action.create');
    },
  },
  watch: {
    partner(newUser, oldUser) {
      if (newUser == null) {
        this.$refs.form.reset();
      } else if (newUser !== oldUser) {
        this.name = this.partner.name;
      }
    },
  },
  mounted() {
    if (this.partner) {
      this.name = this.partner.name;
    }
  },
  methods: {
    handleSubmit() {
      this.$emit('submit', { name: this.name });
      this.$refs.form.reset();
    },
    closeForm() {
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss" scoped></style>
