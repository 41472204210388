<template>
  <div>
    <v-card>
      <v-container>
        <v-form
          ref="form"
          v-model="valid"
          novalidate="true"
          @submit.prevent="handleSubmit"
        >
          <v-card-title>
            <v-row>
              <v-col cols="11">
                <span class="headline">{{ headline.capitalize() }}</span>
              </v-col>
              <v-col cols="1" class="text-right">
                <v-btn icon @click.stop="closeForm">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-card-title>
          <v-card-text>
            <div>
              <v-row>
                <v-col
                  :cols="8"
                  align-self="center"
                >
                  <v-autocomplete
                    v-model="locationId"
                    :disabled="isDisabled('location')"
                    :items="locationsToDisplay"
                    item-text="loc_name"
                    item-value="loc_id"
                    :label="$t('form.label.location')"
                    hide-no-data
                    chips
                    hide-details
                    auto-select-first
                    :rules="[rules.required]"
                    @change="changeOnLocation"
                  />
                </v-col>
                <v-col
                  :cols="4"
                  align-self="center"
                >
                  <v-btn
                    block
                    :disabled="isDisabled('location')"
                    @click.stop="dialogLocation = true"
                  >
                    {{ $t('form.label.newLocation') }}
                  </v-btn>
                </v-col>
              </v-row>
            </div>
            <div v-if="locationId">
              <v-select
                v-model="category"
                :disabled="disabledInput"
                :items="categories"
                item-text="category_name"
                item-value="category_id"
                label="Category"
                hide-no-data
                chips
                attach
                :rules="[rules.required]"
              />
              <v-text-field
                id="name"
                v-model="name"
                :disabled="isDisabled('name')"
                :label="$t('form.label.zoneName')"
                name="name"
                :rules="[rules.required]"
              />
              <v-row>
                <v-col
                  :cols="8"
                  align-self="center"
                >
                  <v-select
                    v-model="sensor"
                    :items="availableDevices"
                    item-text="name"
                    item-value="id"
                    :disabled="!category"
                    :label="$t('form.label.sensor')"
                    hide-no-data
                    chips
                    attach
                    :rules="[rules.required]"
                  />
                </v-col>
                <v-col
                  :cols="4"
                  align-self="center"
                >
                  <v-btn
                    :disabled="disableButton"
                    block
                    @click.stop="dialogDevice = true; toogle = true;"
                  >
                    {{ $t('form.label.newDevice') }}
                  </v-btn>
                </v-col>
              </v-row>
              <v-text-field
                v-model="gpsLatitude"
                :label="$t('form.label.gpslatitude')"
                name="Gpslatitude"
                :rules="[rules.required, rules.gpsCoordinates]"
              />
              <v-text-field
                v-model="gpsLongitude"
                :label="$t('form.label.gpslongitude')"
                name="gpsLongitude"
                :rules="[rules.required, rules.gpsCoordinates]"
              />
              <v-btn
                @click.stop="openMapDialog = true"
              >
                {{ $t('form.label.mapButton') }}
                <v-icon> mdi-map-marker </v-icon>
              </v-btn>
              <ManagementMapContainer
                v-if="openMapDialog"
                :gpslat="parseFloat(gpsLatitude) || 0"
                :gpslng="parseFloat(gpsLongitude) || 0"
                @close="closeMapDialog"
                @submit="submit"
              />
              <div v-if="category">
                <div v-if="category===devicesCategories.N3Zone">
                  <ZoneForm
                    :zone="zone"
                    :alert-text="alertText"
                    :color="color"
                    :location="locationId"
                    :show-test-integration-dialog="showTestIntegrationDialog"
                    :add-from-another-form="addWithRestriction"
                    @updated="getUpdatedZone"
                    @clearTestIntegrationDialogFlag="clearTestIntegrationDialogFlag"
                  />
                </div>
                <div v-if="category===devicesCategories.N3Robot">
                  <RobotForm
                    :zone="zone"
                    :alert-text="alertText"
                    :color="color"
                    :show-test-integration-dialog="showTestIntegrationDialog"
                    :add-from-another-form="addWithRestriction"
                    @updated="getUpdatedZone"
                  />
                </div>
                <div v-if="category===devicesCategories.N3Environment">
                  <EnvironmentForm :zone="zone" :add-from-another-form="addWithRestriction" @updated="getUpdatedZone" />
                </div>
                <v-checkbox
                  v-model="sendNotification"
                  :label="$t('form.label.excludedZone')"
                  input-value="true"
                />
              </div>
            </div>
            <v-dialog v-model="dialogLocation" max-width="640px">
              <LocationFormContainer
                :names="locationsNames"
                :coordinates="locationsCoordinates"
                @close="closeDialogLocation"
                @createLocation="createLocation"
              />
            </v-dialog>
            <v-dialog v-if="toogle" v-model="dialogDevice" max-width="640px">
              <DeviceFormContainer
                :device="deviceToEdit"
                :locations="locationsToDisplay"
                :disabled="disabledInputs"
                :add-from-another-form="true"
                :is-update="false"
                @createDevice="createDevice"
                @closeDeviceDialog="closeDialogDevice"
              />
            </v-dialog>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn
              color="success"
              type="submit"
              :disabled="!valid"
              block
            >
              {{ submitLabel }}
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-container>
    </v-card>
  </div>
</template>

<script>
/* eslint-disable */
import { required, number, beaconId, gpsCoordinates, } from '@/validation/rules';
import RobotForm from './RobotForm';
import ZoneForm from './ZoneForm';
import EnvironmentForm from './EnvironmentForm';
import { fetchLocationById } from '@/data';
import ManagementMapContainer from './MapContainer';
import DeviceFormContainer from '@/views/Management/Devices/FormContainer';
import LocationFormContainer from '@/views/Management/Locations/FormContainer';
import { CategoriesNames, SubcategoriesCategories, SubcategoriesNames } from '@/model/CategoriesObjects';

const DEFAULT_ZONE = {
  name: '',
  sensor: undefined,
  category: '',
  locationId: undefined,
  sendNotification: true,
  gpsLatitude: '',
  gpsLongitude: '',
  partnerId: null
};

export default {
  name: 'ManagmentZoneForm',
  components: {
    RobotForm,
    ZoneForm,
    EnvironmentForm,
    ManagementMapContainer,
    LocationFormContainer,
    DeviceFormContainer
  },
  props: {
    zone: {
      type: Object,
      default: null,
    },
    locations: {
      type: Array,
      default: null,
    },
    devices: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Array,
      default: () => [],
    },
    categories: {
      type: Array,
      default: () => [],
    },
    addFromAnotherForm: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      newLocation: null,
      newDevice:null,
      dialogDevice:false,
      dialogLocation: false,
      valid: true,
      excludedDays: ['6', '0'],
      zoneSize: '',
      expectedCleaningTime: '',
      color: '',
      alertText: '',
      showTestIntegrationDialog: false,
      roomId: '',
      floorId: '',
      serverUrl: '',
      minTemperature: '',
      maxTemperature: '',
      minHumidity: '',
      maxHumidity: '',
      minCo2: '',
      maxCo2: '',
      minDust: '',
      maxDust: '',
      disabledInput: false,
      sendCleanPilot: false,
      testPassed: false,
      openMapDialog: false,
      toogle: false,
      zoneValue: {...DEFAULT_ZONE},
      devicesArray: [],
      devicesCategories: CategoriesNames,
      rules: {
        required,
        number,
        beaconId,
        gpsCoordinates,
      },
    };
  },
  computed: {
    disabledInputs() {
      return (this.category === this.devicesCategories.N3Robot) ? ['category', 'location'] : ['category', 'subcategory', 'location'];
    },
    addWithRestriction() {
      return this.addFromAnotherForm;
    },
    disableButton() {
      return !this.category;
    },
    deviceToEdit() {
      return {
        category: this.category,
        locationId: this.locationId,
        subcategory: this.deviceSubcategory,
        name: '',
        sensorIdentifier: '',
        externalEquipment: '',
        gpsLatitude: '',
        gpsLongitude: '',
        partnerId: '',
      }
    },
    deviceSubcategory() {
      if (this.category === this.devicesCategories.N3Zone) {
        return SubcategoriesNames.Beacon;
      } else if (this.category === this.devicesCategories.N3Robot) {
        return null;
      } else if ( this.category === this.devicesCategories.N3Environment) {
        return SubcategoriesNames.N3IAQ;
      }

      return null;
    },
    headline() {
      if(this.addFromAnotherForm) {
        return this.$t('entity.add', { entity: this.$tc('common.zone', 1) });
      }
      if (this.zone) {
        return this.$t('entity.update', { entity: this.$tc('common.zone', 1) });
      }

      return this.$t('entity.add', { entity: this.$tc('common.zone', 1) });
    },
    submitLabel() {
      if(this.addFromAnotherForm) {
        return this.$t('action.create');
      }
      if (this.zone) {
        return this.$t('action.update');
      }

      return this.$t('action.create');
    },
    locationsToDisplay() {
      const locationsArray = Array.from(this.locations);
      if (this.newLocation) {
        locationsArray.unshift(this.newLocation);
      }

      return locationsArray;
    },
    locationsNames() {
      if (this.locations.loc_name) {
        return this.locations.map((e) => e.loc_name.toLowerCase().trim());
      }

      return [];
    },
    // devicesArray() {
    //   const deviceArray = Array.from(this.devices);
    //   if (this.newDevice) {
    //     const newDeviceMaped = {
    //       id: this.newDevice.device_id,
    //       name: this.newDevice.name,
    //       location: this.newDevice.n3location,
    //       subcategory: this.newDevice.sub_category_name,
    //       partnerId: this.newDevice.partner_id,
    //     }
    //     deviceArray.unshift(newDeviceMaped);
    //   }
    //   return deviceArray;
    // },
    availableDevices(){
      const available = this.devicesArray.filter((e) => {
        return e.location && e.location.loc_id === this.locationId && SubcategoriesCategories[e.subcategory] === this.category;
      })
      return available;
    },
    name: {
      get: function(){
        return this.zoneValue.name;
      },
      set: function(name){
        this.zoneValue.name = name;
      }
    },
    sensor: {
      get: function(){
        return this.zoneValue.sensor;
      },
      set: function(sensor){
        this.zoneValue.sensor = sensor;
      }
    },
    gpsLatitude: {
      get: function(){
        return this.zoneValue.gpsLatitude;
      },
      set: function(gpsLatitude){
        this.zoneValue.gpsLatitude = gpsLatitude;
      }
    },
    gpsLongitude: {
      get: function(){
        return this.zoneValue.gpsLongitude;
      },
      set: function(gpsLongitude){
        this.zoneValue.gpsLongitude = gpsLongitude;
      }
    },
    locationId: {
      get: function(){
        return this.zoneValue.locationId;
      },
      set: function(locationId){
        this.zoneValue.locationId = locationId;
      }
    },
    category: {
      get: function(){
        return this.zoneValue.category;
      },
      set: function(category){
        this.zoneValue.category = category;
      }
    },
    sendNotification: {
      get: function(){
        return this.zoneValue.sendNotification;
      },
      set: function(sendNotification){
        this.zoneValue.sendNotification = sendNotification;
      }
    },
    locationsCoordinates() {
      const locationsCoordinates = [];
      this.locationsToDisplay.forEach((location) => {
        locationsCoordinates.push({
          lat: location.gpsLatitude,
          long: location.gpsLongitude,
          clientId: location.clientIdentifier,
          name: location.name,
        });
      });

      return locationsCoordinates;
    },
  },
  watch: {
    zone(newZone, oldZone) {
      if(this.addWithRestriction && newZone !== null) {
        this.zoneValue = Object.assign({}, this.zone);
        this.enableInput();
        this.showTestIntegrationDialog= false;
        this.sendCleanPilot= false;
        this.setLatLngFromLocation(this.locationId);
        this.testPassed= false;
      }
      else if (newZone === null) {
        this.$refs.form.reset();
        this.enableInput();
        this.zoneValue = {...DEFAULT_ZONE};
        this.showTestIntegrationDialog= false;
        this.sendCleanPilot= false;
        this.testPassed= false;
      } else if (newZone !== oldZone) {
        this.excludedDays = [];
        this.zoneValue = Object.assign({}, this.zone);
        this.disableInput();
      }
    },
    locationId(newLocation, oldLocation) {
      if (newLocation !== null) {
        this.setLatLngFromLocation(this.locationId);
      }
    },
    devices(newDevices) {
      this.devicesArray = Array.from(newDevices);
    }
  },
  mounted() {
    if (this.zone) {
      this.zoneValue = Object.assign({}, this.zone);
      if (this.zone.category) {
        this.disableInput();
      }
    }
    else{
      this.enableInput();
    }
    this.devicesArray = Array.from(this.devices);
  },
  methods: {
    disableInput() {
      this.disabledInput = true;
    },
    enableInput() {
      this.disabledInput = false;
    },
    changeOnLocation(){
      this.zoneValue.sensor = '';
    },
    isDisabled(fieldName) {
      return this.disabled.includes(fieldName);
    },
    async setLatLngFromLocation(locationId) {
      const fetchedLocation = await fetchLocationById(locationId);
      this.gpsLatitude = fetchedLocation[0].gps_latitude;
      this.gpsLongitude = fetchedLocation[0].gps_longitude;
    },
    submit(submitted) {
      this.gpsLatitude = submitted.lat;
      this.gpsLongitude = submitted.lng;
      this.closeMapDialog();
    },
    closeMapDialog() {
      this.openMapDialog = false;
    },
    handleSubmit() {
      if (this.category === CategoriesNames.N3Zone || this.category === CategoriesNames.N3Robot) {
        if (this.checkConditions()) {
          this.submitZone();
        }
      } else if (this.category){
        this.submitZone();
      }
    },
    submitZone() {
      const selectedDevice = this.devicesArray.find((e) => e.id === this.sensor);
      this.$emit('submit', {
        category: this.category,
        name: this.name,
        sensor: this.sensor,
        gpsLatitude: this.gpsLatitude,
        gpsLongitude: this.gpsLongitude,
        locationId: this.locationId,
        zoneSize: this.zoneSize,
        expectedCleaningTime: this.expectedCleaningTime,
        sendNotification: this.sendNotification,
        excludedDays: this.excludedDays,
        roomId: this.roomId ? this.roomId.trim() : '',
        serverUrl: this.serverUrl ? this.serverUrl.trim() : '',
        sendCleanPilot: this.sendCleanPilot,
        floorId: this.floorId,
        minTemperature: this.minTemperature,
        maxTemperature: this.maxTemperature,
        minHumidity: this.minHumidity,
        maxHumidity: this.maxHumidity,
        minCo2: this.minCo2,
        maxCo2: this.maxCo2,
        minDust: this.minDust,
        maxDust: this.maxDust,
        partnerId: selectedDevice?.partnerId,
      });
    },
    checkConditions() {
      if (this.sendCleanPilot === true) {
        if (this.testPassed === false) {
          this.alertText = this.$t('integrationTest.notConductedTestTitle');
          this.color = 'info';
          this.showTestIntegrationDialog = true;

          return false;
        }
      }
      else{
        this.serverUrl = '';
        this.roomId = '';
      }

      return true;
    },
    resetValues() {
      if(this.$refs.form !== undefined){
        this.$refs.form.reset();
      }
      this.enableInput();
      this.sendNotification = true;
      this.showTestIntegrationDialog= false;
      this.sendCleanPilot= false;
      this.testPassed= false;
    },
    getUpdatedZone(zone) {
      const {
        zoneSize, expectedCleaningTime, excludedDays, roomId, url, sendCleanPilot, testPassed,
        floorId, minTemperature, maxTemperature, minHumidity, maxHumidity, minCo2,
        maxCo2, minDust, maxDust } = zone;
      this.zoneSize = zoneSize;
      this.expectedCleaningTime = expectedCleaningTime;
      this.excludedDays = excludedDays;
      this.roomId = roomId;
      this.serverUrl = url;
      this.testPassed = testPassed;
      this.sendCleanPilot = sendCleanPilot;
      this.floorId = floorId;
      this.minTemperature = minTemperature;
      this.maxTemperature = maxTemperature;
      this.minHumidity = minHumidity;
      this.maxHumidity = maxHumidity;
      this.minCo2 = minCo2;
      this.maxCo2 = maxCo2;
      this.minDust = minDust;
      this.maxDust = maxDust;
    },
    createLocation(location) {
      this.newLocation = location;
      this.locationId = location.loc_id;
    },
    createDevice(device) {
      const newDeviceMaped = {
        id: device.device_id,
        name: device.name,
        location: device.n3location,
        subcategory: device.sub_category_name,
        partnerId: device.partner_id,
      }
      this.devicesArray.unshift(newDeviceMaped);
      this.sensor = device.device_id;
      this.toogle = false;
    },
    clearTestIntegrationDialogFlag() {
      this.showTestIntegrationDialog = false;
    },
    closeForm() {
      this.$emit('close');
      this.$refs.form.reset();
    },
    closeDialogLocation() {
      this.dialogLocation = false;
    },
    closeDialogDevice() {
      this.dialogDevice = false;
    },
  },
};
</script>

<style scoped>
.label {
  margin-top: 0px;
  width:30%;
  display: inline-block;
  font-size: 1rem;
  color: gray;
}
</style>
