<template>
  <DeviceForm
    ref="deviceForm"
    :device="deviceToEdit"
    :locations="locationsToDisplay"
    :disabled="disabledInputs"
    :add-from-another-form="addWithRestriction"
    :update="update"
    @submit="submit"
    @close="closeDialog"
  />
</template>

<script>
import {
  addDevice, updateDevice, fetchLocationById, relocateDeviceZone,
} from '@/data';
import DeviceForm from '@/views/Management/Devices/Form';
import { EventBus } from '@/eventBus';
import { SensorsTypes } from '@/model/CategoriesObjects';

export default {
  name: 'DpManagementDevices',
  components: {
    DeviceForm,
  },
  inject: ['authGuard'],
  props: {
    locations: {
      type: Array,
      default: null,
    },
    device: {
      type: Object,
      default: null,
    },
    isUpdate: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Array,
      default: null,
    },
    addFromAnotherForm: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    addWithRestriction() {
      return this.addFromAnotherForm;
    },
    locationsToDisplay() {
      return this.locations;
    },
    deviceToEdit() {
      return this.device;
    },
    update() {
      return this.isUpdate;
    },
    disabledInputs() {
      return this.disabled;
    },
  },
  methods: {
    closeDialog() {
      this.$emit('closeDeviceDialog');
    },
    async submit(submitted) {
      const {
        name, subcategoryname, categoryname, sensoridvalue, locationId,
        gpsLatitude, gpsLongitude, externalEquipment, zoneName, partnerId,
      } = submitted;
      const sensoridtype = this.specifySensorType(subcategoryname);
      if (this.deviceToEdit && !this.addFromAnotherForm) {
        if (this.deviceToEdit.category !== 'N3Gateway'
            && this.deviceToEdit.subcategory !== 'N3Evo' && zoneName) {
          const location = await fetchLocationById(locationId);
          const nameOfZoneToRelocate = (zoneName) || '';
          relocateDeviceZone(
            this.deviceToEdit.id,
            locationId,
            parseFloat(location[0].gps_latitude),
            parseFloat(location[0].gps_longitude),
            this.deviceToEdit.category,
            nameOfZoneToRelocate,
            partnerId,
          );
        }

        updateDevice(this.deviceToEdit.id, {
          name,
          sensor_id_type: sensoridtype,
          sensor_id_value: sensoridvalue.toString(),
          location_id: locationId,
          gps_latitude: parseFloat(gpsLatitude),
          gps_longitude: parseFloat(gpsLongitude),
          external_equipment: externalEquipment,
          partner_id: parseInt(partnerId, 10),
        },
        subcategoryname)
          .then((updatedDevice) => {
            this.$emit('updateDevice', updatedDevice);
            this.$refs.deviceForm.resetComponent();
            this.closeDialog();
          })
          .catch((graphQLErrors) => {
            if (graphQLErrors.message.includes('unique_value_type')) {
              EventBus.$emit('generalErrorOccurred', {
                message: 'Sensor with this type and identifier already exists',
              });
            } else {
              throw graphQLErrors;
            }
          })
          .catch((error) => {
            EventBus.$emit('generalErrorOccurred');
            console.error(error); // eslint-disable-line
            this.closeDialog();
          });
      } else {
        addDevice(sensoridtype, sensoridvalue.toString(), categoryname,
          subcategoryname, name, locationId,
          parseFloat(gpsLatitude), parseFloat(gpsLongitude), externalEquipment, parseInt(partnerId, 10))
          .then((createdDevice) => {
            this.$emit('createDevice', createdDevice);
            this.$refs.deviceForm.resetComponent();
            this.closeDialog();
          })
          .catch((graphQLErrors) => {
            if (graphQLErrors.message.includes('unique_value_type')) {
              EventBus.$emit('generalErrorOccurred', {
                message: 'Sensor with this type and identifier already exists',
              });
            } else {
              throw graphQLErrors;
            }
          })
          .catch((error) => {
            EventBus.$emit('generalErrorOccurred');
            console.error(error); // eslint-disable-line
            this.closeDialog();
          });
      }
    },
    specifySensorType(subcategoryname) {
      if (SensorsTypes.IMEI.includes(subcategoryname)) {
        return 'imei';
      } if (SensorsTypes.BEACONID.includes(subcategoryname)) {
        return 'beacon';
      } if (SensorsTypes.BLEID.includes(subcategoryname)) {
        return 'bleid';
      }

      return 'NoN';
    },
  },
};
</script>
<style lang="scss" scoped>
</style>
